import './style.css'
import AdminRoutesTable from '../../../../components/route/admin/AdminRoutesTable/AdminRoutesTable'
import AdminTab, { AdminTabConfig } from '../../../../components/tab/AdminTab/AdminTab'

export default function AdminRoutesPage() {
  return <div className='admin_routes_page'>
    <AdminTab tabIndex={AdminTabConfig.Routes.index}/>
    <AdminRoutesTable/>
  </div>
}
