import './style.css'
import Tab_ from '../../../atoms/Tab/Tab/Tab'
import { ADMIN__AGREEMENTS__PATH, ADMIN__DASHBOARD__PATH, ADMIN__ORDERS__PATH, ADMIN__RATES__PATH, ADMIN__ROUTES__PATH, ADMIN__USERS__PATH, ADMIN__VISITS__PATH } from '../../../config/page_paths'

export const AdminTabConfig = {
  'Dashboard': { 'label': 'Dashboard', 'index': 0 },
  'Routes': { 'label': 'Routes', 'index': 1 },
  'Orders': { 'label': 'Orders', 'index': 2 },
  'Rates': { 'label': 'Rates', 'index': 3 },
  'Users': { 'label': 'Users', 'index': 4 },
  'Visits': { 'label': 'Visits', 'index': 5 },
  'Agreements': { 'label': 'Agreements', 'index': 6 },
}

interface Props {
  tabIndex: number
}

export default function AdminTab({ tabIndex }: Props) {
  return <div className='admin_tab'>
    <Tab_
      activeIndex={tabIndex}
      tabConfigs={[
        { label: AdminTabConfig.Dashboard.label, navigateTo: ADMIN__DASHBOARD__PATH },
        { label: AdminTabConfig.Routes.label, navigateTo: ADMIN__ROUTES__PATH },
        { label: AdminTabConfig.Orders.label, navigateTo: ADMIN__ORDERS__PATH },
        { label: AdminTabConfig.Rates.label, navigateTo: ADMIN__RATES__PATH },
        { label: AdminTabConfig.Users.label, navigateTo: ADMIN__USERS__PATH },
        { label: AdminTabConfig.Visits.label, navigateTo: ADMIN__VISITS__PATH },
        { label: AdminTabConfig.Agreements.label, navigateTo: ADMIN__AGREEMENTS__PATH },
      ]}/>
  </div>
}
