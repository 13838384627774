import { MuiOtpInput } from "mui-one-time-password-input"

interface Props {
  value?: string
  onChange: (value: string) => void
  length: number
  className?: string
}

export default function OtpInput_({ value, onChange, length, className }: Props) {
  return <>
    <MuiOtpInput
      style={{ margin: "auto" }}
      TextFieldsProps={{ type: 'tel' }}
      value={value}
      onChange={onChange}
      length={length}
      className={className}
    />
  </>
}
