import './style.css'
import { useNavigate } from 'react-router-dom'
import { useDebugState } from 'use-named-state'
import { ADMIN__AGREEMENTS__PATH } from '../../../../config/page_paths'
import Divider_ from '../../../../atoms/Divider/Divider'
import TextField_ from '../../../../atoms/TextField/TextField'
import Submit_ from '../../../../atoms/Submit/Submit'
import { adminAgreementCreate } from '../../../../api/agreement/admin_agreement_api'
import { AdminAgreementCreateRequest } from '../../../../models/agreement/AdminAgreement'
import SelectField_ from '../../../../atoms/SelectField/SelectField'
import { DRIVER_STATUS_EMPTY_VALUE } from '../../../user/admin/AdminUserDriverStatus/AdminUserDriverStatus'
import { UserRole } from '../../../../models/user/UserRole'
import { fromUiUserRole, toUiUserRole } from '../../../../utils/user_role_utils'
import FileInput, { FileInputType } from '../../../../atoms/FileInput/FileInput'
import DateTimePicker_ from '../../../../atoms/DateTimePicker/DateTimePicker'

const USER_ROLE_EMPTY_VALUE = '- Empty -'

export default function AdminAgreementCreate() {
  const navigate = useNavigate()
  const [name, setName] = useDebugState<string | undefined>('name', undefined)
  const [number, setNumber] = useDebugState<string | undefined>('number', undefined)
  const [effectiveDate, setEffectiveDate] = useDebugState<any>('effectiveDate', undefined)
  const [userRole, setUserRole] = useDebugState<string | undefined>('userRole', undefined)
  const [files, setFiles] = useDebugState<FileList | undefined>('files', undefined)

  const [isUploading, setIsUploading] = useDebugState<boolean>('isUploading', false)

  function handleSubmit(event: any) {
    event.preventDefault()
    setIsUploading(true)
    if (name && number && effectiveDate && userRole && files) {
      const request = {
        name: name, number: number, effective_date: effectiveDate, user_role: fromUiUserRole(userRole),
      } as AdminAgreementCreateRequest
      adminAgreementCreate(request, files)
        .then(agreement => navigate(`${ADMIN__AGREEMENTS__PATH}/${agreement.agreement_id}`))
        .catch(e => console.log(`AdminAgreementCreate.handleSubmit.adminAgreementCreate: ${e}`))
        .finally(() => setIsUploading(false))
    }
  }

  function buildValues() {
    const values: string[] = [DRIVER_STATUS_EMPTY_VALUE]
    Object.keys(UserRole).forEach(key => {
      const uiRole = toUiUserRole(key as UserRole)
      if (uiRole) {
        values.push(uiRole)
      }
    })
    return values
  }

  return <div className='admin_agreement_create__fields'>
    <Divider_ text='Create new Agreement' className='admin_agreement_create__divider__overview'/>
    <TextField_ label='Name' value={name} onChange={setName} className='admin_agreement_create__name'/>
    <TextField_ label='Number' value={number} onChange={setNumber} className='admin_agreement_create__number'/>
    <DateTimePicker_ label='Effective Date' value={effectiveDate} onChange={setEffectiveDate}
                     className='admin_agreement_create__effective_date'/>
    <SelectField_ label='Document User Role' value={userRole} values={buildValues()}
                  onChange={(newValue) => setUserRole(newValue !== USER_ROLE_EMPTY_VALUE ? newValue : undefined)}
                  className='admin_agreement_create__user_role'/>
    <div className='admin_agreement_create__files_image_input'>
      <FileInput label='Upload PDF' type={FileInputType.PDF} files={files} onChange={setFiles} singleFile={true}/>
    </div>

    <Submit_ onClick={handleSubmit} isUploading={isUploading}
             disabled={!name || !number || !effectiveDate || !userRole || !files}
             className='admin_agreement_create__submit'/>
  </div>
}
