import './style.css'
import { useNavigate } from 'react-router-dom'
import Tab from '@mui/material/Tab'
import { ReactNode } from 'react'
import TabContext from '@mui/lab/TabContext'
import TabPanel from '@mui/lab/TabPanel'
import { useDebugState } from 'use-named-state'
import Tabs_ from '../Tabs'

interface TabConfig {
  label: string
  navigateTo?: string
}

interface TabWithContextConfig {
  label: string
  content: ReactNode
}

interface Props {
  activeIndex?: number
  tabConfigs?: TabConfig[]
  tabWithContextConfig?: TabWithContextConfig[]
}

export default function Tab_({ activeIndex, tabConfigs, tabWithContextConfig }: Props) {
  const navigate = useNavigate()
  const [value, setValue] = useDebugState<number>('value', activeIndex || 0)
  return <>
    {tabWithContextConfig ? <>
      <TabContext value={value.toString()}>
        <Tabs_
          value={Number(value)}
          onChange={(_, value) => setValue(value)}
          content={tabWithContextConfig.map((tab, index) => <Tab key={index} value={index.toString()} label={tab.label} className='tab__button'/>)}
        />
        {tabWithContextConfig.map((tab, index) => <TabPanel key={index} value={index.toString()}>{tab.content}</TabPanel>)}
      </TabContext>
    </> : <>
      {tabConfigs && <>
          <Tabs_
              value={activeIndex}
              onChange={(_, value) => setValue(value)}
              content={tabConfigs.map((tab, index) => <Tab key={index} label={tab.label} onClick={() => tab.navigateTo && navigate(tab.navigateTo)} className='tab__button'/>)}
          />
      </>}
    </>}
  </>
}
