import './style.css'
import DriverAgreementsTable from '../../../../components/agreement/driver/DriverAgreementsTable/DriverAgreementsTable'
import DriverTab, { DriverTabConfig } from '../../../../components/tab/DriverTab/DriverTab'

export default function DriverAgreementsPage() {
  return <div className='driver_agreements_page'>
    <DriverTab tabIndex={DriverTabConfig.Agreements.index}/>
    <DriverAgreementsTable/>
  </div>
}
