import "./style.css"
import ManagerTab, { ManagerTabConfig } from "../../../../components/tab/ManagerTab/ManagerTab"
import ManagerAgreementsTable from "../../../../components/agreement/manager/ManagerAgreementsTable/ManagerAgreementsTable"

export default function ManagerAgreementsPage() {
  return <div className="manager_agreements_page">
    <ManagerTab tabIndex={ManagerTabConfig.Agreements.index}/>
    <ManagerAgreementsTable/>
  </div>
}
