import './style.css'
import { useDebugState } from 'use-named-state'
import TextField_ from '../../../../atoms/TextField/TextField'
import { useNavigate } from 'react-router-dom'
import { ORDER_TRACKS__PATH } from '../../../../config/page_paths'
import SearchIcon_ from "../../../../atoms/icons/SearchIcon"
import Text_ from "../../../../atoms/Text/Text"
import IconButton_ from "../../../../atoms/IconButton/IconButton"

export default function OrderTracks() {
  const navigate = useNavigate()
  const [orderId, setOrderId] = useDebugState<string | undefined>('orderId', undefined)
  const [orderIdValid, setOrderIdValid] = useDebugState<boolean>('orderIdValid', true)

  function handleOnChange(value: string) {
    setOrderIdValid(value.length == 10)
    setOrderId(value)
  }

  function handleOnSearchClick() {
    if (orderIdValid) {
      navigate(`${ORDER_TRACKS__PATH}/${orderId}`)
    }
  }

  return <div className='order_tracks'>
    <div className='order_tracks__fields'>
      <div className='order_tracks__search'>
        <Text_ content="Enter 10-digits Order ID" className='order_tracks__title'/>
        <div className='order_tracks__order_id_and_search_button'>
          <TextField_ value={orderId} onChange={handleOnChange} valid={orderIdValid} textSize='small' className='order_tracks__order_id'/>
          <IconButton_ onClick={handleOnSearchClick} className='order_tracks__search_button'>
            <SearchIcon_ color={(orderIdValid && orderId !== undefined && orderId !== '') ? 'primary' : 'disabled'}/>
          </IconButton_>
        </div>
      </div>
    </div>
  </div>
}
