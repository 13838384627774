import './style.css'
import { toUiUserDriverStatus } from '../../../../utils/user_driver_status_utils'
import { UserDriverStatus } from '../../../../models/user/UserDriverStatus'
import SelectField_ from '../../../../atoms/SelectField/SelectField'

export const DRIVER_STATUS_EMPTY_VALUE = '- Empty -'

interface Props {
  value?: string
  onChange: (value: any) => void
  className?: string
}

export default function AdminUserDriverStatus({ value, onChange, className }: Props) {

  function buildValues() {
    const values: string[] = [DRIVER_STATUS_EMPTY_VALUE]
    Object.keys(UserDriverStatus).forEach(key => values.push(toUiUserDriverStatus(key as UserDriverStatus)))
    return values
  }

  return <>
    <SelectField_
      label='User Driver Status'
      value={value}
      values={buildValues()}
      onChange={(newValue) => onChange(newValue !== DRIVER_STATUS_EMPTY_VALUE ? newValue : undefined)}
      className={className}
    />
  </>
}
