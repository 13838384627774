import './style.css'
import { UserOrder } from '../../../../models/order/UserOrder'
import TextField_ from '../../../../atoms/TextField/TextField'
import Tel from '../../../../atoms/Tel/Tel'
import Divider_ from '../../../../atoms/Divider/Divider'

interface Props {
  order: UserOrder
}

export default function UserOrderCardDriverInfo({ order }: Props) {
  return <>
    <Divider_ text='Driver info' className='order_card__divider__driver_info'/>
    <TextField_ label='Driver name' value={order.driver_name} className='order_card__driver_name'/>
    <Tel label='Driver phone number' value={order.driver_phone_number} className='order_card__driver_phone_number'/>
  </>
}
