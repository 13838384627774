import './style.css'
import { driverRouteDelete, useDriverRoute } from '../../../../api/route/driver_route_api'
import RouteCardBasicInfo from '../../card_info/RouteCardBasicInfo/RouteCardBasicInfo'
import RouteCardRouteInfo from '../../card_info/RouteCardRouteInfo/RouteCardRouteInfo'
import RouteCardStopsDetails from '../../card_info/RouteCardStopsDetails/RouteCardStopsDetails'
import Button_, { ButtonStyle } from '../../../../atoms/Button/Button'
import { DRIVER__ROUTES__PATH } from '../../../../config/page_paths'
import Divider_ from '../../../../atoms/Divider/Divider'
import { useDebugState } from 'use-named-state'
import Snackbar_ from '../../../../atoms/Snackbar/Snackbar'
import CircularProgress_ from "../../../../atoms/CircularProgress/CircularProgress"

interface Props {
  routeId: string
}

export default function DriverRouteCard({ routeId, }: Props) {
  const { data: route } = useDriverRoute(routeId)
  const [errorMessage, setErrorMessage] = useDebugState<string | undefined>('errorMessage', undefined)

  return <>
    {route ? <>
      <div className='driver_route_card__fields'>
        <Divider_ text={`Route overview: ${routeId}`} className='driver_route_card__divider__overview'/>
        <RouteCardRouteInfo route={route}/>
        <RouteCardStopsDetails route={route}/>
        <RouteCardBasicInfo route={route}/>

        <Button_
          label='Delete'
          buttonStyle={ButtonStyle.RED}
          onClick={() => driverRouteDelete(route?.route_id)}
          setErrorMessage={setErrorMessage}
          navigateTo={DRIVER__ROUTES__PATH}
          logPath='DriverRouteCard.driverRouteDelete'
          className='driver_route_card__delete'
        />
      </div>
      <Snackbar_ message={errorMessage} setMessage={setErrorMessage}/>
    </> : <CircularProgress_/>}
  </>
}
