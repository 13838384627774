import './style.css'
import { getBackendUrl } from '../../../../config/env'
import { BaseAgreement } from '../../../../models/agreement/BaseAgreement'
import PDFViewer from '../../../pdf/PDFViewer/PDFViewer'
import Divider_ from '../../../../atoms/Divider/Divider'
import Text_ from "../../../../atoms/Text/Text"
import CloudDownloadIcon_ from "../../../../atoms/icons/CloudDownloadIcon"

interface Props {
  agreement: BaseAgreement
}

export default function AgreementCardDocumentView({ agreement }: Props) {
  return <>
    <Divider_ text='Document view' className='agreement_card__divider__document_view'/>
    <PDFViewer pdfUrl={`${getBackendUrl()}/agreements/${agreement.agreement_id}/file`}/>
    <a href={`${getBackendUrl()}/agreements/${agreement.agreement_id}/file`} className='agreement_card__download_pdf'>
      <CloudDownloadIcon_/>
      <Text_ content="Download PDF"/>
    </a>
  </>
}
