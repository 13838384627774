import './style.css'
import TextField_ from '../../../../atoms/TextField/TextField'
import { AdminOrder } from '../../../../models/order/AdminOrder'
import Tel from '../../../../atoms/Tel/Tel'
import Divider_ from '../../../../atoms/Divider/Divider'

interface Props {
  order: AdminOrder
}

export default function AdminOrderCardDriverInfo({ order }: Props) {
  return <>
    <Divider_ text='Driver info' className='order_card__divider__driver_info'/>
    <TextField_ label='Driver name' value={order.driver_name} className='order_card__driver_name'/>
    <Tel label='Driver phone number' value={order.driver_phone_number} className='order_card__driver_phone_number'/>
    <TextField_ label='Driver ID' value={order.driver_id} className='order_card__driver_id'/>
    <TextField_ label='Internal comment' value={order.internal_comment} className='order_card__internal_comment'/>
  </>
}
