import { useNavigate } from "react-router-dom"
import { useEffect } from "react"
import { LOGIN__PATH, MAIN__PATH } from "../../config/page_paths"
import { storeToken } from "../../utils/redirect_utils"

export default function AuthRedirectPage() {
  const navigate = useNavigate()

  useEffect(() => {
    navigate(storeToken() ? MAIN__PATH : LOGIN__PATH)
  }, [navigate])

  return <></>
}
