import Container from "@mui/material/Container"
import { ReactNode } from "react"
import { Breakpoint } from "@mui/system"

interface Props {
  className?: string
  maxWidth?: Breakpoint | false
  children?: ReactNode
}

export default function Container_({ className, maxWidth, children }: Props) {
  return <>
    <Container className={className} maxWidth={maxWidth}>
      {children}
    </Container>
  </>
}
