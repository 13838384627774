import "./style.css"
import Text_ from "../../../../atoms/Text/Text"
import ManagerTab, { ManagerTabConfig } from "../../../../components/tab/ManagerTab/ManagerTab"

export default function ManagerStripePage() {
  return <div className="manager_stripe_page">
    <ManagerTab tabIndex={ManagerTabConfig.Stripe.index}/>
    <Text_ className="manager_stripe_page__description">
      Login to <a href="https://connect.stripe.com/express_login" target="_blank">Stripe</a> account to get more details
    </Text_>
  </div>
}
