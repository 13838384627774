import './style.css'
import { useDebugState } from 'use-named-state'
import Table_ from '../../../../atoms/Table/Table'
import { useUserOrders } from '../../../../api/order/user_order_api'
import OrderTablesStatusFilter from '../../OrderTablesStatusFilter/OrderTablesStatusFilter'
import { fromUserOrderStatus, toUiOrderStatus } from '../../../../utils/order_status_utils'
import { OrderStatus } from '../../../../models/order/OrderStatus'
import { userTimeZoneDate } from '../../../../utils/time_utils'
import { DATETIME_WIDTH, DRIVER_PHONE_NUMBER_WIDTH, ID_WIDTH, ORDER_STATUS_WIDTH, RATE_WIDTH, SHORT_ADDRESS_WIDTH, USER_NAME_WIDTH } from '../../../../config/table_witdth_config'
import { USER__ORDER_CREATE__PATH } from '../../../../config/page_paths'

export default function UserOrdersTable() {
  const [page, setPage] = useDebugState('page', 0)
  const [pageSize, setPageSize] = useDebugState('pageSize', 10)
  const [orderStatusFilter, setOrderStatusFilter] = useDebugState<OrderStatus | undefined>('orderStatusFilter', undefined)
  const { data: ordersPage } = useUserOrders(page, pageSize, fromUserOrderStatus(orderStatusFilter))
  return <div className='user_orders_table'>
    <OrderTablesStatusFilter value={orderStatusFilter} onChange={setOrderStatusFilter}/>
    <Table_
      entitiesPage={ordersPage}
      onPageChange={setPage}
      onPageSizeChange={setPageSize}
      onRowClickNavigateCommonPath={'/orders/'}
      config={{
        idKey: 'order_id',
        columns: [
          { header: 'Order ID', entityPath: ['order_id'], width: ID_WIDTH },
          { header: 'Status', entityPath: ['status'], modifier: (value: string) => toUiOrderStatus(value as OrderStatus), width: ORDER_STATUS_WIDTH },
          { header: 'Rate', entityPath: ['user_rate'], width: RATE_WIDTH },
          { header: 'Driver phone number', entityPath: ['driver_phone_number'], width: DRIVER_PHONE_NUMBER_WIDTH },
          { header: 'Driver name', entityPath: ['driver_name'], width: USER_NAME_WIDTH },
          { header: 'Pickup from', entityPath: ['pickup_from_short', 'short_address'], width: SHORT_ADDRESS_WIDTH },
          { header: 'Pickup at', entityPath: ['pickup_at_calculated'], modifier: (value: string) => userTimeZoneDate(value), width: DATETIME_WIDTH },
          { header: 'Delivery to', entityPath: ['delivery_to_short', 'short_address'], width: SHORT_ADDRESS_WIDTH },
          { header: 'Delivery at', entityPath: ['delivery_at_calculated'], modifier: (value: string) => userTimeZoneDate(value), width: DATETIME_WIDTH },
        ],
      }}
      onCreateNavigatePath={USER__ORDER_CREATE__PATH}
    />
  </div>
}
