import './style.css'
import { BaseRoute } from '../../../../models/route/BaseRoute'
import Divider_ from '../../../../atoms/Divider/Divider'
import Table_ from '../../../../atoms/Table/Table'
import { userTimeZoneDate } from '../../../../utils/time_utils'

interface Props {
  route: BaseRoute
}

export default function RouteCardStopsDetails({ route }: Props) {
  return <>
    <Divider_ text='Stops details' className='route_card__divider__stops_details'/>
    <Table_
      entities={route.routes}
      config={{
        idKey: 'timestamp_utc',
        columns: [
          { header: 'Address', entityPath: ['address', 'full_address'] },
          { header: 'Date', entityPath: ['datetime'], modifier: (value: string) => userTimeZoneDate(value) },
        ],
      }}
    />
  </>
}
