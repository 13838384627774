import './style.css'
import TextField_ from '../../../../atoms/TextField/TextField'
import { DriverOrder } from '../../../../models/order/DriverOrder'
import Divider_ from '../../../../atoms/Divider/Divider'

interface Props {
  order: DriverOrder
}

export default function DriverOrderCardDriverInfo({ order }: Props) {
  return <>
    <Divider_ text='Driver info' className='order_card__divider__driver_info'/>
    <TextField_ label='Driver rate' value={order.driver_rate} className='order_card__driver_rate'/>
    <TextField_ label='Internal comment' value={order.internal_comment} className='order_card__internal_comment'/>
  </>
}
