import Cookies from "js-cookie"

export function storeToken() {
  const token = Cookies.get("jwt_token")
  if (token) {
    localStorage.setItem("token", token)
    return true
  } else {
    return false
  }
}
