import './style.css'
import DriverRouteCreate from '../../../../components/route/driver/DriverRouteCreate/DriverRouteCreate'
import DriverTab, { DriverTabConfig } from '../../../../components/tab/DriverTab/DriverTab'

export default function DriverRouteCreatePage() {
  return <div className='driver_route_create_page'>
    <DriverTab tabIndex={DriverTabConfig.Routes.index}/>
    <DriverRouteCreate/>
  </div>
}
