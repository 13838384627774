import "./style.css"
import Divider_ from "../../../../atoms/Divider/Divider"
import AgreementCardDocumentInfo from "../../card_info/AgreementCardDocumentInfo/AgreementCardDocumentInfo"
import AgreementCardDocumentView from "../../card_info/AgreementCardDocumentView/AgreementCardDocumentView"
import CircularProgress_ from "../../../../atoms/CircularProgress/CircularProgress"
import { useManagerAgreement } from "../../../../api/agreement/manager_agreement_api"

interface Props {
  agreementId: string
}

export default function ManagerAgreementCard({ agreementId }: Props) {
  const { data: agreement } = useManagerAgreement(agreementId)
  return <>
    {agreement ? <div className="manager_agreement_card__fields">
      <Divider_ text={`Agreement overview: ${agreementId}`} className="manager_agreement_card__divider__overview"/>
      <AgreementCardDocumentInfo agreement={agreement}/>
      <AgreementCardDocumentView agreement={agreement}/>
    </div> : <CircularProgress_/>}
  </>
}
