import './style.css'
import TextField_ from '../../../../atoms/TextField/TextField'
import { userTimeZoneDate } from '../../../../utils/time_utils'
import Divider_ from '../../../../atoms/Divider/Divider'
import { AdminAgreement } from '../../../../models/agreement/AdminAgreement'

interface Props {
  agreement: AdminAgreement
}

export default function AgreementCardBaseInfo({ agreement }: Props) {
  return <>
    <Divider_ text='Base info' className='agreement_card__divider__base_info'/>
    <TextField_ label='Create at' value={userTimeZoneDate(agreement.created_at)}
                className='agreement_card__created_at'/>
    <TextField_ label='Updated at' value={userTimeZoneDate(agreement.updated_at)}
                className='agreement_card__updated_at'/>
    <TextField_ label='User ID' value={agreement.user_id} className='agreement_card__user_id'/>
  </>
}
