import './style.css'
import { OrderStatus } from '../../../models/order/OrderStatus'
import SelectField_ from '../../../atoms/SelectField/SelectField'
import { toUiOrderStatus } from '../../../utils/order_status_utils'

interface Props {
  value?: string
  onChange: (value: any) => void
}

export default function OrderTablesStatusFilter({ value, onChange }: Props) {
  const NO_FILTER = '- No filter -'

  function buildValues() {
    const values: string[] = [NO_FILTER]
    Object.keys(OrderStatus).forEach(key => values.push(toUiOrderStatus(key as OrderStatus)))
    return values
  }

  return <>
    <SelectField_
      label='Filter by status'
      value={value}
      values={buildValues()}
      onChange={(newValue) => {
        if (newValue === NO_FILTER) {
          return onChange(undefined)
        } else {
          return onChange(newValue)
        }
      }}
      className='orders_table__filter_by_status'
    />
  </>
}
