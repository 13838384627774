import { useDebugState } from "use-named-state"
import { OrderCreateV2_AddressAndPackagesRequest, OrderUpdateV2_ConfirmAndAgreementRequest, OrderUpdateV2_DetailsAndPhotosRequest, OrderV2 } from "../../../../models/order/OrderV2"
import { ReactNode, useEffect, useRef } from "react"
import { orderCreateV2_draft, orderGetV2_confirmAndHold, orderGetV2_setUser, orderUpdateV2_pickupAndDeliveryDetailsAndPhotos } from "../../../../api/order/order_v2_api"
import { useUser } from "../../../layout/Layout/Layout"
import { PageState } from "./PageState"
import { AddressRequest } from "../../../../models/Address"
import OrderCreateV2_1_Address from "./OrderCreateV2_1_Address"
import OrderCreateV2_2_Package from "./OrderCreateV2_2_Package"
import OrderCreateV2_3_PickupAndDeliveryDetails from "./OrderCreateV2_3_PickupAndDeliveryDetails"
import UserOrderCreateV2_6_Login from "./UserOrderCreateV2_6_Login"
import OrderCreateV2_7_PaymentSetup from "./OrderCreateV2_7_PaymentSetup"
import OrderCreateV2_8_ConfirmAndAgreement from "./OrderCreateV2_8_ConfirmAndAgreement"
import OrderCreateV2_9_Done from "./OrderCreateV2_9_Done"
import { OrderPackage } from "../../../../models/order/OrderPackage"
import OrderCreateV2_4_PhotosDecision from "./OrderCreateV2_4_PhotosDecision"
import { scrollToElement } from "./ScrollUtils"
import { OrderStatus } from "../../../../models/order/OrderStatus"
import OrderCreateV2_5_PhotosUploadAndProceed from "./OrderCreateV2_5_PhotosUploadAndProceed"
import { OrderFiles } from "../../../../models/order/OrderFiles"
import UserOrderCreateV2_8FooterInto from "./OrderCreateV2_FooterInto"
import { UserRole } from "../../../../models/user/UserRole"
import ManagerOrderCreateV2_6_Email from "./ManagerOrderCreateV2_6_Email"
import Title from "./Title"
import Text_ from "../../../../atoms/Text/Text"
import { useUserAgreements } from "../../../../api/agreement/user_agreement_api"
import { storeToken } from "../../../../utils/redirect_utils"


export interface OrderPackage2 {
  length_in?: number
  length_in_valid?: boolean
  width_in?: number
  width_in_valid?: boolean
  height_in?: number
  height_in_valid?: boolean
  weight_lb?: number
  weight_lb_valid?: boolean
}

interface Props {
  order?: OrderV2
  setOrder: (order: OrderV2) => void
  mutateOrder: () => void
}

export default function OrderCreateV2({ order, setOrder, mutateOrder }: Props) {
  const { user, mutateUser } = useUser()
  const { data: agreementsPage } = useUserAgreements()

  const [pageState, setPageState] = useDebugState<PageState>("pageState", PageState.ShowAddress)

  useEffect(() => {
    if (order) {
      console.log("useEffect: order -> storeToken")
      if (storeToken()) {
        console.log("useEffect: order -> storeToken.mutateUser")
        mutateUser && mutateUser()
      }
    }
  }, [order]);

  // Calculate PageState and calculatePageState useStates [order]
  useEffect(() => {
    if (!order) {
      console.log("useEffect: OrderCreateV2.!order -> ShowAddress")
      setPageState(PageState.ShowAddress)
    } else {
      console.log("useEffect: OrderCreateV2.order")
      setPageState(calculatePageState(order))
      if (!photosDecision) {
        setPhotosDecision(calculatePhotosDecision(order.files))
      }
      if (!pickupFrom) {
        setPickupFrom(order.pickup_from)
      }
      if (!deliveryTo) {
        setDeliveryTo(order.delivery_to)
      }

      if (!packages) {
        setPackages(order.packages)
      }
      if (!email) {
        setEmail(order.email)
      }

      if (!order.user_id && user && user.role !== UserRole.MANAGER) {
        orderGetV2_setUser(order.order_id)
          .then(() => mutateOrder && mutateOrder())
      }
    }
  }, [order, user])

  function calculatePageState(order: OrderV2): PageState {
    let newPageState = pageState
    switch (order.status) {
      case OrderStatus.NEW__ADDRESS_AND_PACKAGE:
        newPageState = PageState.ShowPickupAndDeliveryDetails
        break
      case OrderStatus.NEW__DETAILS_AND_PHOTOS:
        if (!order.user_id) {
          newPageState = PageState.ShowLogin
          break
        } else if (!order.user_has_payment_setup) {
          newPageState = PageState.ShowPaymentSetup
          break
        } else {
          newPageState = PageState.ShowConfirmAndAgreement
          break
        }
      case OrderStatus.PAYMENT_SETUP_REQUESTED:
        newPageState = PageState.ShowPaymentSetupRequested
        break
      case OrderStatus.PAYMENT_SETUP_FAILED:
        newPageState = PageState.ShowPaymentSetupFailed
        break
      case OrderStatus.PAYMENT_SETUP_SUCCESS:
        newPageState = PageState.ShowConfirmAndAgreement
        break
      case OrderStatus.PAYMENT_HOLD_REQUESTED:
        newPageState = PageState.ShowConfirmAndAgreementRequested
        break
      case OrderStatus.PAYMENT_HOLD_FAILED:
        newPageState = PageState.ShowConfirmAndAgreementFailed
        break
      case OrderStatus.PAYMENT_HOLD_SUCCESS:
        newPageState = PageState.ShowDone
        break
      default: {
      }
    }
    return newPageState
  }

  useEffect(() => {
    // Scroll to depends on PageState
    console.log(`useEffect: OrderCreateV2.pageState ${pageState}`)
    switch (pageState) {
      case PageState.ShowAddress:
        scrollToElement([])
        break
      case PageState.ShowPackage:
        scrollToElement([addressRef,])
        break
      case PageState.ShowPickupAndDeliveryDetails:
        scrollToElement([addressRef, packageRef,])
        break
      case PageState.ShowPhotosDecision:
        scrollToElement([addressRef, packageRef, pickupAndDeliveryDetailsRef,])
        break
      case PageState.ShowPhotosUploadAndProceed:
        scrollToElement([addressRef, packageRef, pickupAndDeliveryDetailsRef, photosDecisionRef,])
        break
      case PageState.ShowLogin:
        scrollToElement([addressRef, packageRef, pickupAndDeliveryDetailsRef, photosDecisionRef, photosUploadAndProceedRef,])
        break
      case PageState.ShowPaymentSetup:
        scrollToElement([addressRef, packageRef, pickupAndDeliveryDetailsRef, photosDecisionRef, photosUploadAndProceedRef, loginRef,])
        break
      case PageState.ShowPaymentSetupRequested:
        scrollToElement([addressRef, packageRef, pickupAndDeliveryDetailsRef, photosDecisionRef, photosUploadAndProceedRef, loginRef,])
        break
      case PageState.ShowPaymentSetupFailed:
        scrollToElement([addressRef, packageRef, pickupAndDeliveryDetailsRef, photosDecisionRef, photosUploadAndProceedRef, loginRef,])
        break
      case PageState.ShowConfirmAndAgreement:
        scrollToElement([addressRef, packageRef, pickupAndDeliveryDetailsRef, photosDecisionRef, photosUploadAndProceedRef, loginRef, paymentSetupRef,])
        break
      case PageState.ShowConfirmAndAgreementRequested:
        scrollToElement([addressRef, packageRef, pickupAndDeliveryDetailsRef, photosDecisionRef, photosUploadAndProceedRef, loginRef, paymentSetupRef,])
        break
      case PageState.ShowConfirmAndAgreementFailed:
        scrollToElement([addressRef, packageRef, pickupAndDeliveryDetailsRef, photosDecisionRef, photosUploadAndProceedRef, loginRef, paymentSetupRef,])
        break
      case PageState.ShowDone:
        scrollToElement([addressRef, packageRef, pickupAndDeliveryDetailsRef, photosDecisionRef, photosUploadAndProceedRef, loginRef, paymentSetupRef, confirmAndAgreementRef,])
        break
    }
  }, [pageState])

  useEffect(() => {
    if (pageState === PageState.ShowPaymentSetupRequested || pageState === PageState.ShowConfirmAndAgreementRequested) {
      console.log("useEffect: OrderCreateV2.pageState: ShowPaymentSetupRequested || ShowConfirmAndAgreementRequested")
      // const fetchData = async () => mutateOrder()
      const fetchData = async () => {
        mutateOrder()
      }
      const intervalId = setInterval(fetchData, 1000)
      fetchData()
      const stopPollingTimeoutId = setTimeout(() => {
        clearInterval(intervalId)
      }, 30000)

      return () => {
        clearInterval(intervalId)
        clearTimeout(stopPollingTimeoutId)
      };
    }
  }, [pageState])

  const visibleIf = (condition: boolean): string => condition ? "block visible" : "block"

  // region PageState.Address
  const addressRef = useRef<HTMLDivElement>(null)

  const [pickupFrom, setPickupFrom] = useDebugState<AddressRequest | undefined>("pickupFrom", undefined)
  const [deliveryTo, setDeliveryTo] = useDebugState<AddressRequest | undefined>("deliveryTo", undefined)

  function showAddress(): ReactNode {
    return <div className="user_order_create_v2__address" ref={addressRef}>
      <OrderCreateV2_1_Address
        pageState={pageState}
        disabled={pageState > PageState.ShowAddress}
        pickUpFrom={pickupFrom} setPickupFrom={onPickupFromChange}
        deliveryTo={deliveryTo} setDeliveryTo={onDeliveryToChange}
      />
    </div>
  }

  function onPickupFromChange(value: AddressRequest) {
    setPickupFrom(value)
    if (deliveryTo) {
      setPageState(PageState.ShowPackage)
    }
  }

  function onDeliveryToChange(value: AddressRequest) {
    setDeliveryTo(value)
    if (pickupFrom) {
      setPageState(PageState.ShowPackage)
    }
  }

  // endregion PageState.Address

  // region PageState.Package
  const packageRef = useRef<HTMLDivElement>(null)

  const [packages, setPackages] = useDebugState<OrderPackage2[]>("packages", [{}])

  function showPackage(): ReactNode {
    const visibleCondition = pageState >= PageState.ShowPackage
    const disableCondition = pageState > PageState.ShowPackage
    return <div className={`user_order_create_v2__packages ${visibleIf(visibleCondition)}`} ref={packageRef}>
      {visibleCondition && <OrderCreateV2_2_Package
          disabled={disableCondition}
          packages={packages} setPackages={setPackages}
          onPackageComplete={() => orderCreateV2_draft(buildOrderCreateV2_AddressAndPackagesRequest())}
          thenAction={(order: OrderV2) => {
            setOrder(order)
            window.history.pushState({}, "", `/orders-create-v2/${order.order_id}`)
          }}
      />}
    </div>
  }

  function buildOrderCreateV2_AddressAndPackagesRequest(): OrderCreateV2_AddressAndPackagesRequest {
    return {
      pickup_from: pickupFrom,
      delivery_to: deliveryTo,
      packages: packages
        .filter(p => p.length_in !== undefined && p.width_in !== undefined && p.height_in !== undefined)
        .map(p => {
          return { length_in: p.length_in, width_in: p.width_in, height_in: p.height_in, weight_lb: p.weight_lb, } as OrderPackage
        }),
      manager_id: user?.role === UserRole.MANAGER ? user.user_id : undefined,
    } as OrderCreateV2_AddressAndPackagesRequest
  }

  // endregion PageState.Package

  // region PageState.PickupAndDeliveryDetails
  const pickupAndDeliveryDetailsRef = useRef<HTMLDivElement>(null)

  const [pickupAtAsap, setPickupAtAsap] = useDebugState<boolean>("pickupAtAsap", true)
  const [pickupAt, setPickupAt] = useDebugState<any>("pickupAt", undefined)
  const [pickupContactless, setPickupContactless] = useDebugState<boolean>("pickupContactless", true)
  const [pickupPhoneNumber, setPickupPhoneNumber] = useDebugState<string | undefined>("pickupPhoneNumber", undefined)
  const [deliveryAt, setDeliveryAt] = useDebugState<any>("deliveryAt", undefined)
  const [deliveryAtAsap, setDeliveryAtAsap] = useDebugState<boolean>("deliveryAtAsap", true)
  const [deliveryContactless, setDeliveryContactless] = useDebugState<boolean>("deliveryContactless", true)
  const [deliveryPhoneNumber, setDeliveryPhoneNumber] = useDebugState<string | undefined>("deliveryPhoneNumber", undefined)
  const [files, setFiles] = useDebugState<FileList | undefined>("files", undefined)

  function showPickupAndDeliveryDetails() {
    const visibleCondition = pageState >= PageState.ShowPickupAndDeliveryDetails
    const disableCondition = pageState > PageState.ShowPhotosDecision
    return <div className={`user_order_create_v2__pickup_and_delivery_details ${visibleIf(visibleCondition)}`}
                ref={pickupAndDeliveryDetailsRef}>
      {visibleCondition && <OrderCreateV2_3_PickupAndDeliveryDetails
          disabled={disableCondition}
          userRate={order?.user_rate || 0}
          pickupAtAsap={pickupAtAsap} setPickupAtAsap={setPickupAtAsap}
          pickupAt={pickupAt} setPickupAt={setPickupAt}
          pickupContactless={pickupContactless} setPickupContactless={setPickupContactless}
          pickupPhoneNumber={pickupPhoneNumber} setPickupPhoneNumber={setPickupPhoneNumber}
          deliveryAt={deliveryAt} setDeliveryAt={setDeliveryAt}
          deliveryAtAsap={deliveryAtAsap} setDeliveryAtAsap={setDeliveryAtAsap}
          deliveryContactless={deliveryContactless} setDeliveryContactless={setDeliveryContactless}
          deliveryPhoneNumber={deliveryPhoneNumber} setDeliveryPhoneNumber={setDeliveryPhoneNumber}
      />}
    </div>
  }

  // endregion PageState.PickupAndDeliveryDetails

  // region PageState.PhotosDecision
  const photosDecisionRef = useRef<HTMLDivElement>(null)

  function calculatePhotosDecision(files?: OrderFiles): boolean | undefined {
    if (!files) {
      return undefined
    } else {
      return (files.photos_on_creation?.length || 0) > 0
    }
  }

  const [photosDecision, setPhotosDecision] = useDebugState<boolean | undefined>("photosDecision", calculatePhotosDecision(order?.files))

  function showPhotosDecision() {
    const visibleCondition = pageState >= PageState.ShowPickupAndDeliveryDetails
    const disableCondition = pageState > PageState.ShowPhotosUploadAndProceed
    return <div className={`user_order_create_v2__photo_decision ${visibleIf(visibleCondition)}`}
                ref={photosDecisionRef}>
      {visibleCondition && <>
          <OrderCreateV2_4_PhotosDecision
              disabled={disableCondition}
              photosDecision={photosDecision} setPhotosDecision={onPhotosDecisionChange}
          />
      </>}
    </div>
  }

  function onPhotosDecisionChange(value: boolean) {
    setPhotosDecision(value)
    setPageState(PageState.ShowPhotosDecision)
    if (!value) {
      setFiles(undefined)
    }
  }

  // endregion PageState.PhotosDecision

  // region PageState.PhotosUploadAndProceed
  const photosUploadAndProceedRef = useRef<HTMLDivElement>(null)

  function showPhotosUploadAndProceed() {
    const visibleCondition = photosDecision !== undefined && pageState >= PageState.ShowPhotosDecision
    return <div className={`user_order_create_v2__photos_upload_and_proceed ${visibleIf(visibleCondition)}`}
                ref={photosUploadAndProceedRef}>
      {visibleCondition && <OrderCreateV2_5_PhotosUploadAndProceed
          disabled={pageState > PageState.ShowPhotosUploadAndProceed}
          photosDecision={photosDecision}
          files={files} setFiles={onFilesChange}
          onProceedClick={onProceedClick}
      />}
    </div>
  }

  function onFilesChange(value: FileList) {
    setFiles(value)
    setPageState(PageState.ShowPhotosUploadAndProceed)
  }

  function onProceedClick() {
    if (order) {
      orderUpdateV2_pickupAndDeliveryDetailsAndPhotos(order.order_id, {
        pickup_at: pickupAt,
        pickup_contactless: pickupContactless,
        pickup_phone_number: pickupPhoneNumber,
        delivery_at: deliveryAt,
        delivery_contactless: deliveryContactless,
        delivery_phone_number: deliveryPhoneNumber,
      } as OrderUpdateV2_DetailsAndPhotosRequest, files)
        .then((order: OrderV2) => setOrder(order))
    }
  }

  // endregion PageState.PhotosUploadAndProceed

  // region PageState.Login
  const loginRef = useRef<HTMLDivElement>(null)

  const [email, setEmail] = useDebugState<string | undefined>("email", undefined)

  function showLogin(): ReactNode {
    const disableCondition = pageState > PageState.ShowLogin
    if (user?.role === UserRole.MANAGER) {
      const visibleCondition = pageState >= PageState.ShowLogin
      return <div className={`user_order_create_v2__login ${visibleIf(visibleCondition)}`} ref={loginRef}>
        {visibleCondition && mutateOrder && <ManagerOrderCreateV2_6_Email
            disabled={disableCondition}
            orderId={order!!.order_id}
            email={email} setEmail={setEmail}
            mutateOrder={mutateOrder}
        />}
      </div>
    } else {
      const visibleCondition = pageState >= PageState.ShowLogin
      return <div className={`user_order_create_v2__login ${visibleIf(visibleCondition)}`} ref={loginRef}>
        {visibleCondition && <UserOrderCreateV2_6_Login disableCondition={disableCondition} email={email} setEmail={setEmail}/>}
      </div>
    }
  }

  // endregion PageState.Login

  // region PageState.PaymentSetup
  const paymentSetupRef = useRef<HTMLDivElement>(null)

  function showPaymentSetup(): ReactNode {
    const visibleCondition = pageState === PageState.ShowPaymentSetup && !!order?.user_id && !order.user_has_payment_setup
    return <>
      {pageState >= PageState.ShowPaymentSetup && <Title text="Payment setup" className=""/>}
      {pageState === PageState.ShowPaymentSetupRequested && <Text_ content="Waiting for confirmation from Stripe" className=""/>}
      {pageState === PageState.ShowPaymentSetupFailed && <Text_ content="Confirmation from Stripe was failed" className=""/>}
      <div className={`user_order_create_v2__payment_setup ${visibleIf(visibleCondition)}`} ref={paymentSetupRef}>
        {visibleCondition && mutateOrder && <OrderCreateV2_7_PaymentSetup order={order} mutateOrder={mutateOrder}/>}
      </div>
      {pageState >= PageState.ShowConfirmAndAgreement && <Text_ content="Payment Intent was setup" className="order_create_v2__payment_setup__title"/>}
    </>
  }

  // endregion PageState.PaymentSetup

  // region PageState.ConfirmAndAgreement
  const confirmAndAgreementRef = useRef<HTMLDivElement>(null)

  function showConfirmAndAgreement(): ReactNode {
    const visibleCondition = pageState >= PageState.ShowConfirmAndAgreement && order !== undefined
    return <>
      <div className={`user_order_create_v2__confirm_and_agreement ${visibleIf(visibleCondition)}`}
           ref={confirmAndAgreementRef}>
        {visibleCondition && <OrderCreateV2_8_ConfirmAndAgreement
            pageState={pageState}
            handleConfirmClick={onShowConfirmAndAgreementComplete}
            agreementsPage={agreementsPage}
            order={order}
            isConfirmed={pageState > PageState.ShowConfirmAndAgreement}
            isAgreementAcknowledged={pageState > PageState.ShowConfirmAndAgreement}
        />}
      </div>
      {pageState === PageState.ShowConfirmAndAgreementRequested && <>
          <Text_ content="Stripe is holding the amount..." className=""/>
      </>}
    </>
  }

  function onShowConfirmAndAgreementComplete() {
    if (order) {
      orderGetV2_confirmAndHold(order.order_id, window.location.href, {
        agreement_ids: agreementsPage?.content.map(e => e.agreement_id)
      } as OrderUpdateV2_ConfirmAndAgreementRequest).then((order: OrderV2) => setOrder(order))
    }
  }

  // endregion PageState.ConfirmAndAgreement

  // region PageState.Done
  const doneRef = useRef<HTMLDivElement>(null)

  function showDone(): ReactNode {
    const visibleCondition = pageState == PageState.ShowDone
    return <div className={`user_order_create_v2__done ${visibleIf(visibleCondition)}`} ref={doneRef}>
      {visibleCondition && <OrderCreateV2_9_Done/>}
    </div>
  }

  // endregion PageState.Done

  function showFooter(): ReactNode {
    return <div className="user_order_create_v2__footer">
      <UserOrderCreateV2_8FooterInto/>
    </div>
  }

  return <div className="user_order_create_v2" style={{ maxWidth: "900px", margin: "auto" }}>
    {showAddress()}
    {showPackage()}
    {showPickupAndDeliveryDetails()}
    {showPhotosDecision()}
    {showPhotosUploadAndProceed()}
    {showLogin()}
    {showPaymentSetup()}
    {showConfirmAndAgreement()}
    {showDone()}
    {showFooter()}
    <div className="user_order_create_v2__extra_for_scroll"/>
  </div>
}
