import { useMobile } from "./utils"
import Text_ from "../../../../atoms/Text/Text"

interface Props {
  text: string
  className: string
}

export default function Title({ text, className }: Props) {
  return <>
    <Text_
      variant="h5"
      sx={{
        alignSelf: "center", fontWeight: "600", fontSize: useMobile() ? "20px" : "31px", padding: "1em", paddingBottom: "0.5em", textAlign: "center"
      }}
      className={className}
    >
      {text}
    </Text_>
  </>
}
