import './style.css'
import { useDebugState } from 'use-named-state'
import Table_ from '../../../../atoms/Table/Table'
import { AGREEMENT_EFFECTIVE_DATE_WIDTH, AGREEMENT_ID_WIDTH, AGREEMENT_NAME_WIDTH, AGREEMENT_STATUS_WIDTH, DATETIME_WIDTH, ID_WIDTH, USER_NAME_WIDTH } from '../../../../config/table_witdth_config'
import { useAdminAgreements } from '../../../../api/agreement/admin_agreement_api'
import { userTimeZoneDate } from '../../../../utils/time_utils'
import { ADMIN__AGREEMENT_CREATE__PATH } from '../../../../config/page_paths'

export default function AdminAgreementsTable() {
  const [page, setPage] = useDebugState('page', 0)
  const [pageSize, setPageSize] = useDebugState('pageSize', 10)
  const { data: usersPage } = useAdminAgreements(page, pageSize)
  return <>
    <Table_
      entitiesPage={usersPage}
      onPageChange={setPage}
      onPageSizeChange={setPageSize}
      onRowClickNavigateCommonPath='/admin/agreements/'
      onCreateNavigatePath={ADMIN__AGREEMENT_CREATE__PATH}
      config={{
        idKey: 'agreement_id',
        columns: [
          { header: 'User Role', entityPath: ['user_role'], width: USER_NAME_WIDTH },
          { header: 'Status', entityPath: ['status'], width: AGREEMENT_STATUS_WIDTH },
          { header: 'Name', entityPath: ['name'], width: AGREEMENT_NAME_WIDTH },
          { header: 'Number', entityPath: ['number'], width: AGREEMENT_EFFECTIVE_DATE_WIDTH },
          { header: 'Effective Date', entityPath: ['effective_date'], modifier: (value: string) => userTimeZoneDate(value), width: AGREEMENT_EFFECTIVE_DATE_WIDTH },
          { header: 'Created At', entityPath: ['created_at'], modifier: (value: string) => userTimeZoneDate(value), width: DATETIME_WIDTH },
          { header: 'Updated At', entityPath: ['updated_at'], modifier: (value: string) => userTimeZoneDate(value), width: DATETIME_WIDTH },
          { header: 'User ID', entityPath: ['user_id'], width: ID_WIDTH },
          { header: 'File name', entityPath: ['document_metadata', 'file_name'], width: USER_NAME_WIDTH },
          { header: 'Bytes', entityPath: ['document_metadata', 'size_bytes'], width: USER_NAME_WIDTH },
          { header: 'Agreement ID', entityPath: ['agreement_id'], width: AGREEMENT_ID_WIDTH },
        ],
      }}
    />
  </>
}
