import "./style.css"

import { useDebugState } from "use-named-state"
import { useEffect } from "react"
import TextField_ from "../../../../atoms/TextField/TextField"
import { meUpdate } from "../../../../api/user/user_api"
import { useUser } from "../../../layout/Layout/Layout"
import Tel from "../../../../atoms/Tel/Tel"
import Button_, { ButtonStyle } from "../../../../atoms/Button/Button"
import Dialog_ from "../../../../atoms/Dialog/Dialog"

export default function UserRequestMissingInfo() {
  const { user, mutateUser } = useUser()
  const [name, setName] = useDebugState<string | undefined>("name", undefined)
  const [phoneNumber, setPhoneNumber] = useDebugState<string | undefined>("phoneNumber", undefined)

  useEffect(() => {
    if ((!name && user?.name) || (!phoneNumber && user?.phone_number)) {
      console.log("useEffect: UserRequestMissingInfo.user.name || user.phone_number")
      setName(user?.name)
      setPhoneNumber(user?.phone_number)
    }
  }, [user])

  function isChanged() {
    return user?.name !== name || user?.phone_number !== phoneNumber
  }

  function handleClose(): void {
    if (isChanged()) {
      meUpdate({
        name: name, phone_number: phoneNumber,
      }).then(() => {
        mutateUser && mutateUser()
      })
    } else {
      console.log("UserRequestMissingInfo.handleClose: isChanged()==false")
    }
  }

  return <>
    <Dialog_
      isOpen={true}
      title="User Profile"
      contentText="Please provide required information:"
      contentTextClassName="user_request_missing_info__message"
      content={<>
        {!user?.name &&
            <TextField_ label="Name" value={name} onChange={setName} className="user_request_missing_info__name"/>}
        {!user?.phone_number &&
            <Tel label="Phone number *" value={phoneNumber} onChange={setPhoneNumber} className="user_request_missing_info__phone_number"/>}
      </>}
      contentClassName="user_request_missing_info"
      actions={<Button_ label="Save" buttonStyle={ButtonStyle.CONTAINED} disabled={!isChanged()} onClickVoid={handleClose} className="user_request_missing_info__save_button"/>}
      onClose={handleClose}
    />
  </>
}
