import './style.css'
import { BaseOrder } from '../../../../models/order/BaseOrder'
import TextField_ from '../../../../atoms/TextField/TextField'
import Divider_ from '../../../../atoms/Divider/Divider'

interface Props {
  order: BaseOrder
}

export default function OrderCardRouteInfo({ order }: Props) {
  return <>
    <Divider_ text='Route info' className='order_card__divider__route_info'/>
    <TextField_ label='Distance, mi' value={order.distance_data.distance_mi} className='order_card__distance_mi'/>
    <TextField_ label='Duration, sec' value={order.distance_data.duration_seconds} className='order_card__duration_seconds'/>
  </>
}
