import './style.css'
import Button_, { ButtonStyle } from '../../../../atoms/Button/Button'
import { UserDriverStatus } from '../../../../models/user/UserDriverStatus'
import { adminUserAcceptDriver, adminUserRejectDriver } from '../../../../api/user/admin_user_api'
import { BaseUser } from '../../../../models/user/BaseUser'

import Divider_ from '../../../../atoms/Divider/Divider'
import Text_ from "../../../../atoms/Text/Text"
import DoneIcon_ from "../../../../atoms/icons/DoneIcon"
import CloseIcon_ from "../../../../atoms/icons/CloseIcon"

interface Props {
  user: BaseUser
  mutateUser: () => void
}

export default function AdminUserDriverActionButtons({ user, mutateUser }: Props) {

  function showActionButtons() {
    return user.driver_status !== null && user.driver_status !== UserDriverStatus.REJECTED && user.driver_status !== UserDriverStatus.NOT_INTERESTED
  }

  return <>
    {showActionButtons() && <>
        <Divider_ text='Admin Actions' className='admin_user_card__divider__admin_actions'/>
        <div className='admin_user_card__user_driver_status_action_buttons'>
          {user.driver_status === UserDriverStatus.REQUESTED && <>
              <Text_ content="User wants to be a Driver:" className='admin_user_card__user_driver_status_action_buttons__text'/>
              <Button_
                  label='Accept'
                  disabled={user.driver_status !== UserDriverStatus.REQUESTED}
                  onClick={() => adminUserAcceptDriver(user.user_id).then(_ => mutateUser())}
                  logPath='AdminUserCard.adminUserAcceptDriver'
                  startIcon={<DoneIcon_/>}
              />
          </>}
            <Button_
                label='Reject'
                disabled={user.driver_status !== UserDriverStatus.REQUESTED && user.driver_status !== UserDriverStatus.ACCEPTED}
                buttonStyle={ButtonStyle.RED}
                onClick={() => adminUserRejectDriver(user.user_id).then(_ => mutateUser())}
                logPath='AdminUserCard.adminUserRejectDriver'
                startIcon={<CloseIcon_/>}
            />
        </div>
    </>}
  </>
}
