import './style.css'
import Text_ from "../../../atoms/Text/Text"

export default function HelpPage() {
  return <div className='help_page'>
    <Text_ className='help_page__description'>
      If you have any questions, please, email us: <a href='mailto:help@iujex.com'>help@iujex.com</a>
    </Text_>
  </div>
}
