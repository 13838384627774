import './style.css'
import AdminDashboard from '../../../../components/dashboard/AdminDashboard/AdminDashboard'
import AdminTab, { AdminTabConfig } from '../../../../components/tab/AdminTab/AdminTab'

export default function AdminDashboardPage() {
  return <div className='admin_dashboard_page'>
    <AdminTab tabIndex={AdminTabConfig.Dashboard.index}/>
    <AdminDashboard/>
  </div>
}
