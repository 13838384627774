import './style.css'
import { useParams } from 'react-router-dom'
import AdminRateCard from '../../../../components/rate/AdminRateCard/AdminRateCard'
import AdminTab, { AdminTabConfig } from '../../../../components/tab/AdminTab/AdminTab'

export default function AdminRateCardPage() {
  const { rateId } = useParams()
  return <div className='admin_rate_card_page'>
    <AdminTab tabIndex={AdminTabConfig.Rates.index}/>
    <AdminRateCard rateId={rateId as string}/>
  </div>
}
