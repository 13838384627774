import { ReactNode } from "react"
import Paper from "@mui/material/Paper"

interface Props {
  elevation?: number
  className?: string
  children: ReactNode
}

export default function Paper_({ elevation, className, children }: Props) {
  return <>
    <Paper elevation={elevation} className={className}>
      {children}
    </Paper>
  </>
}
