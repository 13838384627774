import Button_ from "../../../../atoms/Button/Button"
import Title from "./Title"
import Checkbox_ from "../../../../atoms/Checkbox/Checkbox"
import { useDebugState } from "use-named-state"
import { BaseAgreement } from "../../../../models/agreement/BaseAgreement"
import { getBackendUrl } from "../../../../config/env"
import { PageResponse } from "../../../../models/Page"
import Map_ from "../../../../atoms/Map/Map"
import { OrderV2 } from "../../../../models/order/OrderV2"
import { PageState } from "./PageState"
import { useMobile } from "./utils"
import { buildMarkers, buildPolylines } from "../../../../atoms/Map/utils"
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material"

interface Props {
  pageState: PageState
  order: OrderV2
  handleConfirmClick: () => void
  agreementsPage?: PageResponse<BaseAgreement>
  isConfirmed: boolean
  isAgreementAcknowledged: boolean
}

export default function OrderCreateV2_8_ConfirmAndAgreement({ pageState, order, handleConfirmClick, agreementsPage, isConfirmed, isAgreementAcknowledged }: Props) {

  function buildGreetings() {
    if (order.user_name) {
      return `${order.user_name}, please`
    } else {
      return "Please"
    }
  }

  const [confirmOrder, setConfirmOrder] = useDebugState<boolean>("confirmOrder", isConfirmed)
  const [agreementAcknowledged, setAgreementAcknowledged] = useDebugState<boolean>("agreementAcknowledged", isAgreementAcknowledged)

  function onAgreementChange(newValue: boolean) {
    if (!newValue) {
      setAgreementAcknowledged(false)
    } else {
      setAgreementAcknowledged(true)
    }
  }

  return <div style={{
    display: "flex", flexDirection: "column", paddingTop: "0.25em", paddingBottom: "1.5em", // backgroundColor: "grey"
  }}>
    <Title text={`${buildGreetings()}, review your shipping order:`}
           className="user_order_create_v2__confirm_and_agreement__title"/>
    <div style={{ marginBottom: "1em", marginTop: "1em" }}>
      <div
        style={{ display: "flex", flexDirection: useMobile() ? "column" : "row", columnGap: "1.5em", rowGap: "1.5em" }}>
        <Map_
          className=""
          width={useMobile() ? "100%" : "400px"}
          polylines={buildPolylines(order)} markers={buildMarkers([order])}
        />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ fontWeight: 600, marginBottom: "0.5em", marginTop: "0.5em" }}>Order '{order.order_id}'</div>

          <div style={{ fontWeight: 600, marginTop: "0.5em" }}>Contacts</div>
          <div>Email: {order.email}</div>
          <div>Name: {order.user_name}</div>

          <div style={{ fontWeight: 600, marginTop: "0.5em" }}>Pickup</div>
          <div>From: {order.pickup_from?.full_address}</div>
          <div>At: {order?.pickup_at || "ASAP"}</div>
          {order.pickup_contactless ? <div>Contactless</div> : <div>Contact phone: {order.pickup_phone_number}</div>}

          <div style={{ fontWeight: 600, marginTop: "1em" }}>Delivery</div>
          <div>From: {order.delivery_to?.full_address}</div>
          <div>At: {order?.delivery_at || "ASAP"}</div>
          {order.delivery_contactless ? <div>Contactless</div> : <div>Contact phone: {order.delivery_phone_number}</div>}

          <div style={{ fontWeight: 500, marginTop: "1em" }}>Package(s)</div>
          <div>
            {order.packages?.map((p, i) => {
              return <div key={i}>{i + 1}. {p.length_in}x{p.height_in}x{p.width_in} in, {p.weight_lb} lbs</div>
            })}
          </div>

          <div style={{ fontWeight: 500, marginTop: "1em" }}>Your rate is ${order.user_rate}</div>

        </div>
      </div>
    </div>
    <Checkbox_
      label="By clicking 'Submit' you confirm the rate mentioned above and authorize a pre-authorization on your saved
payment method. We won't charge your card until your package is delivered."
      value={confirmOrder}
      onChange={setConfirmOrder}
      typographyStyle={{ fontSize: "0.75em" }}
      disabled={pageState !== PageState.ShowConfirmAndAgreement}
      className="user_order_create_v2__confirm_and_agreement__confirm_rate_and_details_checkbox"
    />
    <div>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={agreementAcknowledged} onChange={_ => onAgreementChange(!agreementAcknowledged)}
                             size="small"/>}
          label="I have read and agreed with the following Terms and Conditions:"
          componentsProps={{ typography: { fontSize: "0.75em" } }}
          disabled={pageState !== PageState.ShowConfirmAndAgreement}
          className="user_order_create_v2__confirm_and_agreement__read_agreement_checkbox"
        />
      </FormGroup>
      <div style={{
        display: "flex", flexDirection: "column", rowGap: "0.5em", paddingLeft: "2.5em", paddingBottom: "2em"
      }}>
        {agreementsPage?.content?.map((agreement: BaseAgreement, i: number) => {
          return <a href={`${getBackendUrl()}/agreements/${agreement?.agreement_id}/file`}
                    style={{ fontSize: "0.75em" }} key={i}>{agreement?.name}</a>
        })}
      </div>
    </div>
    {pageState >= PageState.ShowConfirmAndAgreement && <div style={{ margin: "auto" }}>
        <Button_
            label="Submit"
            onClickVoid={handleConfirmClick}
            disabled={(!confirmOrder || !agreementAcknowledged) || (pageState > PageState.ShowConfirmAndAgreement)}
            className="user_order_create_v2__confirm_and_agreement__submit_button"
        />
    </div>}
  </div>
}
