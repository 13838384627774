import { OrderStatus } from '../models/order/OrderStatus'

const NEW_ADDRESS_AND_PACKAGE_STATUS = 'Draft'
const NEW__DETAILS_AND_PHOTOS_STATUS = 'New'
const PAYMENT_SETUP_REQUESTED_STATUS = 'Payment setup'
const PAYMENT_SETUP_FAILED_STATUS = 'Payment setup failure'
const PAYMENT_SETUP_SUCCESS_STATUS = 'Payment was setup'
const ACCEPTED_STATUS = 'Quote accepted'
const REJECTED_STATUS = 'Quote rejected'
const PAYMENT_HOLD_REQUESTED_STATUS = 'Payment hold requested'
const PAYMENT_HOLD_FAILED_STATUS = 'Payment hold was failed'
const PAYMENT_HOLD_SUCCESS_STATUS = 'Payment was held'
const ASSIGNED_STATUS = 'Driver assigned'
const PICKED_UP_STATUS = 'Driver on a route'
const DELIVERED_STATUS = 'Delivered'
const PAYMENT_CAPTURE_REQUESTED_STATUS = 'Payment capture requested'
const PAYMENT_CAPTURE_FAILED_STATUS = 'Payment capture failure'
const PAYMENT_CAPTURE_SUCCESS_STATUS = 'Payment captured'

export function toUiOrderStatus(orderStatus: OrderStatus): string {
  switch (orderStatus) {
    case OrderStatus.NEW__ADDRESS_AND_PACKAGE:
      return NEW_ADDRESS_AND_PACKAGE_STATUS
    case OrderStatus.NEW__DETAILS_AND_PHOTOS:
      return NEW__DETAILS_AND_PHOTOS_STATUS
    case OrderStatus.PAYMENT_SETUP_REQUESTED:
      return PAYMENT_SETUP_REQUESTED_STATUS
    case OrderStatus.PAYMENT_SETUP_FAILED:
      return PAYMENT_SETUP_FAILED_STATUS
    case OrderStatus.PAYMENT_SETUP_SUCCESS:
      return PAYMENT_SETUP_SUCCESS_STATUS
    case OrderStatus.ACCEPTED:
      return ACCEPTED_STATUS
    case OrderStatus.REJECTED:
      return REJECTED_STATUS
    case OrderStatus.PAYMENT_HOLD_REQUESTED:
      return PAYMENT_HOLD_REQUESTED_STATUS
    case OrderStatus.PAYMENT_HOLD_FAILED:
      return PAYMENT_HOLD_FAILED_STATUS
    case OrderStatus.PAYMENT_HOLD_SUCCESS:
      return PAYMENT_HOLD_SUCCESS_STATUS
    case OrderStatus.ASSIGNED:
      return ASSIGNED_STATUS
    case OrderStatus.PICKED_UP:
      return PICKED_UP_STATUS
    case OrderStatus.DELIVERED:
      return DELIVERED_STATUS
    case OrderStatus.PAYMENT_CAPTURE_REQUESTED:
      return PAYMENT_CAPTURE_REQUESTED_STATUS
    case OrderStatus.PAYMENT_CAPTURE_FAILED:
      return PAYMENT_CAPTURE_FAILED_STATUS
    case OrderStatus.PAYMENT_CAPTURE_SUCCESS:
      return PAYMENT_CAPTURE_SUCCESS_STATUS
  }
}

export function fromUserOrderStatus(userOrderStatus?: string): OrderStatus | undefined {
  switch (userOrderStatus) {
    case '':
      return undefined
    case NEW_ADDRESS_AND_PACKAGE_STATUS:
      return OrderStatus.NEW__ADDRESS_AND_PACKAGE
    case NEW__DETAILS_AND_PHOTOS_STATUS:
      return OrderStatus.NEW__DETAILS_AND_PHOTOS
    case PAYMENT_SETUP_REQUESTED_STATUS:
      return OrderStatus.PAYMENT_SETUP_REQUESTED
    case PAYMENT_SETUP_FAILED_STATUS:
      return OrderStatus.PAYMENT_SETUP_FAILED
    case PAYMENT_SETUP_SUCCESS_STATUS:
      return OrderStatus.PAYMENT_SETUP_SUCCESS
    case ACCEPTED_STATUS:
      return OrderStatus.ACCEPTED
    case REJECTED_STATUS:
      return OrderStatus.REJECTED
    case PAYMENT_HOLD_REQUESTED_STATUS:
      return OrderStatus.PAYMENT_HOLD_REQUESTED
    case PAYMENT_HOLD_FAILED_STATUS:
      return OrderStatus.PAYMENT_HOLD_FAILED
    case PAYMENT_HOLD_SUCCESS_STATUS:
      return OrderStatus.PAYMENT_HOLD_SUCCESS
    case ASSIGNED_STATUS:
      return OrderStatus.ASSIGNED
    case PICKED_UP_STATUS:
      return OrderStatus.PICKED_UP
    case DELIVERED_STATUS:
      return OrderStatus.DELIVERED
    case PAYMENT_CAPTURE_REQUESTED_STATUS:
      return OrderStatus.PAYMENT_CAPTURE_REQUESTED
    case PAYMENT_CAPTURE_FAILED_STATUS:
      return OrderStatus.PAYMENT_CAPTURE_FAILED
    case PAYMENT_CAPTURE_SUCCESS_STATUS:
      return OrderStatus.PAYMENT_CAPTURE_SUCCESS
  }
}
