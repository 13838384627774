import { createRoot } from 'react-dom/client'
import './style.css'
import { BrowserRouter } from 'react-router-dom'
import { getBackendUrl } from './config/env'
import { SWRConfig } from 'swr'
import { StrictMode } from 'react'
import App from './App'
import { http } from './api/http'

const swrConfig = {
  refreshInterval: 30000, // ms = 30s
  fetcher: (url: string) => http.getData(getBackendUrl() + url)
}

createRoot(document.getElementById('root') as HTMLElement)
  .render(
    <StrictMode>
      <BrowserRouter>
        <SWRConfig value={swrConfig}>
          <App/>
        </SWRConfig>
      </BrowserRouter>
    </StrictMode>
  )
