import './style.css'
import TextField_ from '../../../../atoms/TextField/TextField'
import { UserOrder } from '../../../../models/order/UserOrder'
import { AdminOrder } from '../../../../models/order/AdminOrder'
import Divider_ from '../../../../atoms/Divider/Divider'

interface Props {
  order: UserOrder | AdminOrder
}

export default function OrderCardPaymentInfo({ order }: Props) {
  return <>
    <Divider_ text='Payment info' className='order_card__divider__payment_info'/>
    <TextField_ label='Stripe Customer ID' value={order.stripe_data?.customer_id} className='order_card__customer_id'/>
    <TextField_ label='Stripe Payment Method ID' value={order.stripe_data?.payment_method_id} className='order_card__payment_method_id'/>
    <TextField_ label='Stripe Payment Intent ID' value={order.stripe_data?.payment_intent_id} className='order_card__payment_intent_id'/>
  </>
}
