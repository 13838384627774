import './style.css'
import { useDebugState } from 'use-named-state'
import { useDriverOrders, useDriverOrdersAvailable } from '../../../../api/order/driver_order_api'
import { OrderStatus } from '../../../../models/order/OrderStatus'
import { toUiOrderStatus } from '../../../../utils/order_status_utils'
import { userTimeZoneDate } from '../../../../utils/time_utils'
import Table_ from '../../../../atoms/Table/Table'
import { DATETIME_WIDTH, DRIVER_DISTANCE_MI_WIDTH, ID_WIDTH, ORDER_STATUS_WIDTH, RATE_WIDTH, SHORT_ADDRESS_WIDTH } from '../../../../config/table_witdth_config'


interface Props {
  availableOnly?: boolean
}

export default function DriverOrdersTable({ availableOnly }: Props) {
  const [page, setPage] = useDebugState('page', 0)
  const [pageSize, setPageSize] = useDebugState('pageSize', 10)
  const { data: ordersAvailablePage } = useDriverOrdersAvailable(page, pageSize)
  const { data: ordersPage } = useDriverOrders(page, pageSize)

  if (availableOnly === true) {
    return <>
      <Table_
        entitiesPage={ordersAvailablePage}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        onRowClickNavigateCommonPath={'/driver/orders/'}
        config={
          {
            idKey: 'order_id',
            columns: [
              { header: 'Pickup from', entityPath: ['pickup_from_short', 'short_address'], width: SHORT_ADDRESS_WIDTH },
              { header: 'Pickup at', entityPath: ['pickup_at_calculated'], modifier: (value: string) => userTimeZoneDate(value), width: DATETIME_WIDTH },
              { header: 'Delivery to', entityPath: ['delivery_to_short', 'short_address'], width: SHORT_ADDRESS_WIDTH },
              { header: 'Delivery at', entityPath: ['delivery_at_calculated'], modifier: (value: string) => userTimeZoneDate(value), width: DATETIME_WIDTH },
              { header: 'Driver rate', entityPath: ['driver_rate'], width: RATE_WIDTH },
              { header: 'Driver extra miles', entityPath: ['driver_extra_miles'], width: DRIVER_DISTANCE_MI_WIDTH },
              { header: 'Order ID', entityPath: ['order_id'], width: ID_WIDTH },
            ],
          }}
      />
    </>
  } else {
    return <>
      <Table_
        entitiesPage={ordersPage}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        onRowClickNavigateCommonPath={'/driver/orders/'}
        config={{
          idKey: 'order_id',
          columns: [
            { header: 'Status', entityPath: ['status'], modifier: (value: string) => toUiOrderStatus(value as OrderStatus), width: ORDER_STATUS_WIDTH },
            { header: 'Pickup from', entityPath: ['pickup_from_short', 'short_address'], width: SHORT_ADDRESS_WIDTH },
            { header: 'Pickup at', entityPath: ['pickup_at_calculated'], modifier: (value: string) => userTimeZoneDate(value), width: DATETIME_WIDTH },
            { header: 'Delivery to', entityPath: ['delivery_to_short', 'short_address'], width: SHORT_ADDRESS_WIDTH },
            { header: 'Delivery at', entityPath: ['delivery_at_calculated'], modifier: (value: string) => userTimeZoneDate(value), width: DATETIME_WIDTH },
            { header: 'Driver rate', entityPath: ['driver_rate'], width: RATE_WIDTH },
            { header: 'Driver extra miles', entityPath: ['driver_extra_miles'], width: DRIVER_DISTANCE_MI_WIDTH },
            { header: 'Order ID', entityPath: ['order_id'], width: ID_WIDTH },
          ],
        }}
      />
    </>
  }
}
