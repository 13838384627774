import './style.css'
import { useDebugState } from 'use-named-state'
import { adminRateCalculate } from '../../../../api/rate/admin_rate_api'
import SelectField_ from '../../../../atoms/SelectField/SelectField'
import TextField_ from '../../../../atoms/TextField/TextField'
import Submit_ from '../../../../atoms/Submit/Submit'
import Divider_ from '../../../../atoms/Divider/Divider'
import { AdminRateCalculateRequest, RateType } from "../../../../models/rate/AdminRate"

export default function AdminRateCalculate() {
  const [rateType, setRateType] = useDebugState<string>('rateType', RateType.CLIENT.toString())
  const [distanceMi, setDistanceMi] = useDebugState<number | undefined>('distanceMi', 100)
  const [lengthIn, setLengthIn] = useDebugState<number | undefined>('lengthIn', 12)
  const [widthIn, setWidthIn] = useDebugState<number | undefined>('widthIn', 12)
  const [heightIn, setHeightIn] = useDebugState<number | undefined>('heightIn', 12)
  const [weightLb, setWeightLb] = useDebugState<number | undefined>('weightLb', 12)

  const [rateValue, setRateValue] = useDebugState<number | undefined>('rateValue', undefined)

  function onSubmit() {
    const request = {
      rate_type: rateType, distance_mi: distanceMi, length_in: lengthIn, width_in: widthIn, height_in: heightIn, weight_lb: weightLb,
    } as AdminRateCalculateRequest

    adminRateCalculate(request)
      .then(result => setRateValue(result))
      .catch(e => console.log(`AdminRateCalculate.onSubmit.adminRateCalculate: ${e}`))
  }

  return <div className='admin_rate_calculate__fields'>
    <Divider_ text='Rate calculator' className='admin_rate_calculate__divider__calculator'/>
    <SelectField_ label='Rate Type' value={rateType} values={Object.keys(RateType)} onChange={setRateType} className='admin_rate_calculate__rate_type'/>
    <TextField_ label='Distance, mi' value={distanceMi} onChange={setDistanceMi} className='admin_rate_calculate__distance_mi'/>
    <TextField_ label='Weight, lb' value={weightLb} onChange={setWeightLb} className='admin_rate_calculate__weight_lb'/>
    <TextField_ label='Length, in' value={lengthIn} onChange={setLengthIn} className='admin_rate_calculate__length_in'/>
    <TextField_ label='Width, in' value={widthIn} onChange={setWidthIn} className='admin_rate_calculate__width_in'/>
    <TextField_ label='Height, in' value={heightIn} onChange={setHeightIn} className='admin_rate_calculate__height_in'/>
    <TextField_ label='Rate, $' value={rateValue} className='admin_rate_calculate__rate'/>

    <Submit_ label='Calculate' onClick={onSubmit} className='admin_rate_calculate__calculate'/>
  </div>
}
