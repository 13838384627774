import "./style.css"
import UserDashboardPage from "../../dashboard/user/UserDashboardPage/UserDashboardPage"
import AdminDashboardPage from "../../dashboard/admin/AdminDashboardPage/AdminDashboardPage"
import DriverDashboardPage from "../../dashboard/driver/DriverDashboardPage/DriverDashboardPage"
import { useUser } from "../../../components/layout/Layout/Layout"
import { UserRole } from "../../../models/user/UserRole"
import UserOrderCreateV2Page from "../../order/user/UserOrderCreateV2Page/UserOrderCreateV2Page"
import ManagerDashboardPage from "../../dashboard/manager/ManagerDashboardPage/ManagerDashboardPage"

export default function MainPage() {
  const { user } = useUser()

  function chooseMainPage(userRole?: UserRole, userSelectedRole?: UserRole) {
    const role = userSelectedRole || userRole
    if (role === undefined) {
      return <UserOrderCreateV2Page/>
    } else if (role === UserRole.USER) {
      return <UserDashboardPage/>
    } else if (role === UserRole.ADMIN) {
      return <AdminDashboardPage/>
    } else if (role === UserRole.DRIVER) {
      return <DriverDashboardPage/>
    } else if (role === UserRole.MANAGER) {
      return <ManagerDashboardPage/>
    }
  }

  return <>
    {chooseMainPage(user?.role, user?.selected_role)}
  </>
}
