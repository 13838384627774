import "./style.css"
import { useDebugState } from "use-named-state"
import Table_ from "../../../../atoms/Table/Table"
import { fromUserOrderStatus, toUiOrderStatus } from "../../../../utils/order_status_utils"
import { OrderStatus } from "../../../../models/order/OrderStatus"
import OrderTablesStatusFilter from "../../OrderTablesStatusFilter/OrderTablesStatusFilter"
import { userTimeZoneDate } from "../../../../utils/time_utils"
import { DATETIME_WIDTH, DISTANCE_EXTRA_MI_WIDTH, ID_WIDTH, ORDER_STATUS_WIDTH, RATE_WIDTH, SHORT_ADDRESS_WIDTH } from "../../../../config/table_witdth_config"
import { useManagerOrders } from "../../../../api/order/manager_order_api"

export default function ManagerOrdersTable() {
  const [page, setPage] = useDebugState("page", 0)
  const [pageSize, setPageSize] = useDebugState("pageSize", 10)
  const [orderStatusFilter, setOrderStatusFilter] = useDebugState<OrderStatus | undefined>("orderStatusFilter", undefined)
  const { data: ordersPage } = useManagerOrders(page, pageSize, fromUserOrderStatus(orderStatusFilter))
  return <div className="manager_orders_table">
    <OrderTablesStatusFilter value={orderStatusFilter} onChange={setOrderStatusFilter}/>
    <Table_
      entitiesPage={ordersPage}
      onPageChange={setPage}
      onPageSizeChange={setPageSize}
      onRowClickNavigateCommonPath={"/manager/orders/"}
      config={{
        idKey: "order_id",
        columns: [
          { header: "Status", entityPath: ["status"], modifier: (value: string) => toUiOrderStatus(value as OrderStatus), width: ORDER_STATUS_WIDTH },
          { header: "User rate", entityPath: ["user_rate"], width: RATE_WIDTH },
          { header: "Driver rate", entityPath: ["driver_rate"], width: RATE_WIDTH },
          { header: "Driver extra miles", entityPath: ["driver_extra_miles"], width: DISTANCE_EXTRA_MI_WIDTH },
          { header: "Driver ID", entityPath: ["driver_id"], width: ID_WIDTH },
          { header: "Pickup from", entityPath: ["pickup_from_short", "short_address"], width: SHORT_ADDRESS_WIDTH },
          { header: "Pickup at", entityPath: ["pickup_at_calculated"], modifier: (value: string) => userTimeZoneDate(value), width: DATETIME_WIDTH },
          { header: "Delivery to", entityPath: ["delivery_to_short", "short_address"], width: SHORT_ADDRESS_WIDTH },
          { header: "Delivery at", entityPath: ["delivery_at_calculated"], modifier: (value: string) => userTimeZoneDate(value), width: DATETIME_WIDTH },
          { header: "Order ID", entityPath: ["order_id"], width: ID_WIDTH },
        ],
      }}
    />
  </div>
}
