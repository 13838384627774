import UserStripeSetupIntent from "../../../user/user/UserStripeSetupIntent/UserStripeSetupIntent/UserStripeSetupIntent"
import Title from "./Title"
import { OrderV2 } from "../../../../models/order/OrderV2"

interface Props {
  order: OrderV2 | undefined
  mutateOrder: () => void
}

export default function OrderCreateV2_7_PaymentSetup({ order, mutateOrder }: Props) {
  return <div style={{ display: "flex", flexDirection: "column", paddingTop: "0.25em", paddingBottom: "1.5em" }}>
    <Title text="Select payment method before final review" className="user_order_create_v2__payment_setup__title"/>
    <div style={{ width: "350px", margin: "auto" }}>
      {order && <UserStripeSetupIntent order={order} mutateOrder={mutateOrder}/>}
    </div>
  </div>
}
