import { http } from '../http'
import { User, UserChangeRoleRequest, UserStripeSetupIntentResponse, UserUpdateRequest } from '../../models/user/User'
import { ACCOUNT_ONBOARDING__PATH } from '../../config/page_paths'

export function getMe() {
  return http.getData<User>('/users/me')
}

export function meUpdate(request: UserUpdateRequest): Promise<User> {
  return http.putData<UserUpdateRequest, User>('/users/me', request)
}

export function meSetupIntentForOrders(orderId: string): Promise<UserStripeSetupIntentResponse> {
  return http.putData<void, UserStripeSetupIntentResponse>(`/users/me/setup-intent-for-orders/${orderId}`)
}

export function meBecomeDriver(value: boolean): Promise<User> {
  return http.putData<UserUpdateRequest, User>(`/users/me/become-a-driver?value=${value}`)
}

export function meBecomeDriverCreateAccount(returnUrl: string, agreement_ids: string[]): Promise<User> {
  return http.putData<UserChangeRoleRequest, User>(
    '/users/me/become-a-driver/create-account',
    {
      return_url: returnUrl,
      refresh_url: ACCOUNT_ONBOARDING__PATH,
      agreement_ids: agreement_ids,
    } as UserChangeRoleRequest
  )
}

export function meBecomeDriverCreateAccountLink(returnUrl: string): Promise<User> {
  return http.putData<void, User>(`/users/me/become-a-driver/create-account-link?return_url=${returnUrl}&refresh_url=${ACCOUNT_ONBOARDING__PATH}`)
}

export function meBecomeManager(value: boolean): Promise<User> {
  return http.putData<UserUpdateRequest, User>(`/users/me/become-a-manager?value=${value}`)
}

export function meBecomeManagerCreateAccount(returnUrl: string, agreement_ids: string[]): Promise<User> {
  return http.putData<UserChangeRoleRequest, User>('/users/me/become-a-manager/create-account', {
    return_url: returnUrl, refresh_url: ACCOUNT_ONBOARDING__PATH, agreement_ids: agreement_ids,
  } as UserChangeRoleRequest)
}

export function meBecomeManagerCreateAccountLink(returnUrl: string): Promise<User> {
  return http.putData<void, User>(`/users/me/become-a-manager/create-account-link?return_url=${returnUrl}&refresh_url=${ACCOUNT_ONBOARDING__PATH}`)
}
