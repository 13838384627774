import "./style.css"
import { driverOrderAssign, useDriverOrdersActive, useDriverOrdersAvailable } from "../../../../api/order/driver_order_api"
import Map_, { SelectedMarker } from "../../../../atoms/Map/Map"
import { DriverOrder, DriverOrderMap } from "../../../../models/order/DriverOrder"
import { useDriverRouteCurrent } from "../../../../api/route/driver_route_api"
import { Marker } from "../../../../atoms/Map/Marker"
import { MarkerType } from "../../../../atoms/Map/MarkerType"
import { packagesToString } from "../../../../utils/package_utils"
import { DRIVER__ORDERS__PATH } from "../../../../config/page_paths"
import { useNavigate } from "react-router-dom"
import { useDebugState } from "use-named-state"
import { buildEncodedPolygons, buildEncodedPolylines, buildMarkers } from "../../../../atoms/Map/utils"

import Text_ from "../../../../atoms/Text/Text";
import Button_, { ButtonStyle } from "../../../../atoms/Button/Button"
import Dialog_ from "../../../../atoms/Dialog/Dialog"

interface OrderWindow {
  order: DriverOrderMap
}

interface OrderAvailableWindow extends OrderWindow {
  isAssigned: boolean
}

export default function DriverMap() {
  const navigate = useNavigate()

  const { data: route } = useDriverRouteCurrent()
  const { data: ordersAvailablePage } = useDriverOrdersAvailable()
  const { data: ordersActivePage, mutate: mutateOrdersActivePage } = useDriverOrdersActive()
  const [selectedMarker, setSelectedMarker] = useDebugState<SelectedMarker | undefined>("selectedMarker", undefined)
  const [openAcknowledgeDialog, setOpenAcknowledgeDialog] = useDebugState<boolean>("openAcknowledgeDialog", false)

  function typography(text: string) {
    return <Text_ content={text} sx={{ fontSize: 14 }} color="text.secondary" gutterBottom/>
  }

  function renderMarkerWindow(marker: Marker) {
    const order = marker.payload.order
    if (marker.type === MarkerType.AVAILABLE_ORDER) {
      const isAssigned = (marker.payload as OrderAvailableWindow).isAssigned
      return <>
        {typography(`ID: ${order.order_id}`)}
        {typography(`Rate: $${order.driver_rate}`)}
        {typography(`Extra miles: ${order.driver_extra_miles}`)}
        {typography(`From: ${order.pickup_from.short_address}`)}
        {typography(`To: ${order.delivery_to.short_address}`)}
        {typography(`Packages: ${packagesToString(order.packages)}`)}
        {!isAssigned &&
            <Button_ label="Assign to me" onClickVoid={() => setOpenAcknowledgeDialog(true)} className="map__assign_to_me_button"/>
        }
      </>
    } else if (marker.type === MarkerType.CURRENT_ORDER) {
      return <>
        {typography(`ID: ${order.order_id}`)}
        {typography(`From: ${order.pickup_from.short_address}`)}
        {typography(`To: ${order.delivery_to.short_address}`)}
        {typography(`Packages: ${packagesToString(order.packages)}`)}
        <Button_ label="Details" onClickVoid={() => navigate(`${DRIVER__ORDERS__PATH}/${order.order_id}`)}></Button_>
      </>
    }
  }

  return <>
    <Dialog_
      isOpen={openAcknowledgeDialog}
      onClose={() => setOpenAcknowledgeDialog(false)}
      title="Order Assignment"
      contentText="Are you sure you want to assign the Order?"
      actions={<>
        <Button_
          label="Assign"
          onClickVoid={() => {
            if (selectedMarker) {
              driverOrderAssign(selectedMarker.entityId, window.location.href)
                .then(() => navigate(DRIVER__ORDERS__PATH))
            }
          }}
          className="driver_map__dialog__assign_button"
        />
        <Button_ label="Cancel" buttonStyle={ButtonStyle.RED} className="driver_map__dialog__cancel_button"/>
      </>}
    />
    {ordersAvailablePage?.content && <>
        <Map_
            polylines={buildEncodedPolylines(route?.distance_data.encoded_polyline, ordersAvailablePage?.content?.filter((order: DriverOrder) => order.order_id == selectedMarker?.entityId)?.map((order: DriverOrder) => order.distance_data.encoded_polyline), ordersActivePage?.content?.map((order: DriverOrder) => order.distance_data.encoded_polyline),)}
            polygons={route && buildEncodedPolygons([route.buffered_route])}
            markers={buildMarkers(ordersActivePage?.content, ordersAvailablePage.content)}
            renderMarkerWindow={renderMarkerWindow}
            className="driver_map"
            selectedMarker={selectedMarker}
            setSelectedMarker={setSelectedMarker}
        />
    </>}
  </>
}
