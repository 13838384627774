import './style.css'
import TextField_ from '../../../../atoms/TextField/TextField'
import Divider_ from '../../../../atoms/Divider/Divider'
import { AdminAgreement } from '../../../../models/agreement/AdminAgreement'

interface Props {
  agreement: AdminAgreement
}

export default function AgreementCardFileInfo({ agreement }: Props) {
  return <>
    <Divider_ text='File info' className='agreement_card__divider__file_info'/>
    <TextField_ label='File Name' value={agreement.document_metadata.file_name} className='agreement_card__file_name'/>
    <TextField_ label='MIME Type' value={agreement.document_metadata.mime_type} className='agreement_card__mime_type'/>
    <TextField_ label='Extension' value={agreement.document_metadata.extension} className='agreement_card__extension'/>
    <TextField_ label='Size Bytes' value={agreement.document_metadata.size_bytes} className='agreement_card__size_bytes'/>
  </>
}
