import useSWR, { SWRResponse } from 'swr'
import {
  AdminRate,
  AdminRateCalculateRequest,
  AdminRateCreateRequest,
  AdminRateUpdateRequest
} from '../../models/rate/AdminRate'
import { http } from '../http'
import { PageResponse } from '../../models/Page'
import { usePageable } from '../../utils/swr_utils'

export function adminRateCreate(request: AdminRateCreateRequest): Promise<AdminRate> {
  return http.postData<AdminRateCreateRequest, AdminRate>('/admin/rates', request)
}

export function useAdminRate(rateId: string): SWRResponse<AdminRate> {
  return useSWR<AdminRate>([`/admin/rates/${rateId}`])
}

export function useAdminRates(page?: number, pageSize?: number): SWRResponse<PageResponse<AdminRate>> {
  return usePageable('/admin/rates/search', { page: page, page_size: pageSize })
}

export function adminRateCalculate(request: AdminRateCalculateRequest): Promise<number> {
  return http.postData<AdminRateCalculateRequest, number>('/admin/rates/calculate', request)
}

export function adminRateUpdate(rateId: string, request: AdminRateUpdateRequest): Promise<AdminRate> {
  return http.putData<AdminRateUpdateRequest, AdminRate>(`/admin/rates/${rateId}`, request)
}

export function adminRateDelete(rateId: string): Promise<void> {
  return http.delete(`/admin/rates/${rateId}`)
}
