import useSWR, { SWRResponse } from 'swr'
import { PageRequest, PageResponse } from '../../models/Page'
import { usePageable } from '../../utils/swr_utils'
import { BaseAgreement } from '../../models/agreement/BaseAgreement'

export function useUserAgreement(agreementId: string): SWRResponse<BaseAgreement> {
  return useSWR<BaseAgreement>([`/agreements/${agreementId}`])
}

export function useUserAgreements(page?: number, pageSize?: number): SWRResponse<PageResponse<BaseAgreement>> {
  return usePageable(
    '/agreements/search',
    {
      page: page,
      page_size: pageSize,
    } as PageRequest<any>
  )
}
