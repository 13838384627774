import useSWR, { SWRResponse } from "swr"
import { http } from "../http"
import { PageRequest, PageResponse } from "../../models/Page"
import { usePageable } from "../../utils/swr_utils"
import { BaseAgreement } from "../../models/agreement/BaseAgreement"

export function useManagerAgreement(agreementId: string): SWRResponse<BaseAgreement> {
  return useSWR<BaseAgreement>([`/manager/agreements/${agreementId}`])
}

export async function managerAgreementDownload(agreementId: string): Promise<string | undefined> {
  return http.getFile(`/manager/agreements/${agreementId}`)
}

export function useManagerAgreements(page?: number, pageSize?: number): SWRResponse<PageResponse<BaseAgreement>> {
  return usePageable("/manager/agreements/search", {
    page: page, page_size: pageSize,
  } as PageRequest<any>)
}
