import './style.css'
import AdminRatesTable from '../../../../components/rate/AdminRatesTable/AdminRatesTable'
import AdminTab, { AdminTabConfig } from '../../../../components/tab/AdminTab/AdminTab'
import AdminRateCalculate from '../../../../components/rate/RateCalculate/AdminRateCalculate/AdminRateCalculate'

export default function DriverRatesPage() {
  return <div className='admin_rates_page'>
    <AdminTab tabIndex={AdminTabConfig.Rates.index}/>
    <AdminRateCalculate/>
    <AdminRatesTable/>
  </div>
}
