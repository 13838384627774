import './style.css'
import Divider_ from '../../../../atoms/Divider/Divider'
import { adminAgreementDelete, adminAgreementSetActive, adminAgreementSetInactive, adminAgreementUpdate, useAdminAgreement } from '../../../../api/agreement/admin_agreement_api'
import AgreementCardBaseInfo from '../../card_info/AgreementCardBaseInfo/AgreementCardBaseInfo'
import AgreementCardStatusInfo from '../../card_info/AgreementCardStatusInfo/AgreementCardStatusInfo'
import AgreementCardFileInfo from '../../card_info/AgreementCardFileInfo/AgreementCardFileInfo'
import AgreementCardDocumentInfo from '../../card_info/AgreementCardDocumentInfo/AgreementCardDocumentInfo'
import AdminAgreementCardDocumentView from '../../card_info/AdminAgreementCardDocumentView/AdminAgreementCardDocumentView'
import Button_, { ButtonStyle } from '../../../../atoms/Button/Button'
import { AgreementStatus } from '../../../../models/agreement/AgreementStatus'
import { ADMIN__AGREEMENTS__PATH } from '../../../../config/page_paths'
import { useDebugState } from 'use-named-state'
import { useEffect } from 'react'
import { AdminUserUpdateRequest } from '../../../../models/user/AdminUsers'
import CircularProgress_ from "../../../../atoms/CircularProgress/CircularProgress"

interface Props {
  agreementId: string
}

export default function AdminAgreementCard({ agreementId }: Props) {
  const { data: agreement, mutate: mutateAgreement } = useAdminAgreement(agreementId)
  const [name, setName] = useDebugState<string | undefined>('name', undefined)
  const [number, setNumber] = useDebugState<string | undefined>('number', undefined)

  function isChanged() {
    return agreement?.name !== name || agreement?.number !== number
  }

  useEffect(() => {
    console.log("useEffect: AdminAgreementCard.agreement")
    setName(agreement?.name)
    setNumber(agreement?.number)
  }, [agreement])

  function handleSaveClick() {
    const request = {
      name: name, number: number,
    } as AdminUserUpdateRequest
    return adminAgreementUpdate(agreementId, request)
      .then(_ => mutateAgreement())
  }

  return <>
    {agreement ?
      <div className="admin_agreement_card__fields">
        <Divider_ text={`Agreement overview: ${agreementId}`}
                  className="admin_agreement_card__divider__overview"/>
        <AgreementCardDocumentInfo agreement={agreement} name={name} setName={setName} number={number} setNumber={setNumber}/>
        <AgreementCardStatusInfo agreement={agreement}/>
        <AgreementCardFileInfo agreement={agreement}/>
        <AgreementCardBaseInfo agreement={agreement}/>
        <AdminAgreementCardDocumentView agreement={agreement}/>
        <div className="admin_agreement_card__action_buttons">
          <Button_ label="Make Active"
                   disabled={agreement.status === AgreementStatus.ACTIVE || agreement.status === AgreementStatus.DELETED}
                   onClick={() => adminAgreementSetActive(agreementId).then(() => mutateAgreement())}
                   logPath="AdminAgreementCard.adminAgreementSetActive"/>
          <Button_ label="Make InActive"
                   disabled={agreement.status === AgreementStatus.INACTIVE || agreement.status === AgreementStatus.DELETED || agreement.status === AgreementStatus.DRAFT}
                   onClick={() => adminAgreementSetInactive(agreementId).then(() => mutateAgreement())}
                   logPath="AdminAgreementCard.adminAgreementSetInactive"/>
        </div>
        <Button_ label="Delete" buttonStyle={ButtonStyle.RED}
                 onClick={() => adminAgreementDelete(agreementId)}
                 navigateTo={ADMIN__AGREEMENTS__PATH}
                 logPath="AdminAgreementCard.adminAgreementDelete"
                 className="admin_agreement_card__delete"/>
        <Button_
          label="Save" disabled={!isChanged()} onClick={handleSaveClick}
          logPath="AdminAgreementCard.handleSaveClick.adminAgreementUpdate"
          className="admin_agreement_card__save"/>
      </div>
      : <CircularProgress_/>
    }
  </>
}
