import "./style.css"
import { createContext, ReactNode, useContext, useEffect } from "react"
import { User } from "../../../models/user/User"
import { getMe, meUpdate } from "../../../api/user/user_api"
import Header from "../../header/Header/Header"
import Footer from "../../../atoms/Footer/Footer"
import { recordError } from "../../../api/error/error_api"
import { useDebugState } from "use-named-state"

interface UserContextData {
  user?: User
  mutateUser?: () => void
}

export const UserContext = createContext<UserContextData>({})

export function useUser() {
  return useContext(UserContext)
}

interface Props {
  children: ReactNode
}

export default function Layout({ children }: Props) {
  const [user, setUser] = useDebugState<User | undefined>("user", undefined)

  useEffect(() => {
    console.log("useEffect: Layout")
    updateUser()
    const handleErrors = (event: any) => {
      console.error("Caught an error:", JSON.stringify(event))
      recordError({
        user_id: user?.user_id, error_message: JSON.stringify(event), path: ""
      } as WebError)
    }
    window.addEventListener("error", handleErrors)
    return () => {
      window.removeEventListener("error", handleErrors)
    }
  }, [])

  function updateUser() {
    console.log("Layout.updateUser.")
    getMe()
      .then(u => {
        console.log("Layout.updateUser.getMe")
        if (!u.timezone) {
          meUpdate({ timezone: Intl.DateTimeFormat().resolvedOptions().timeZone })
        }
        setUser(u)
      })
      .catch(e => {
        console.error(`Layout.updateUser.getMe: ${e}`)
        setUser(undefined)
      })
  }

  return <>
    <UserContext.Provider value={{ user: user, mutateUser: updateUser } as UserContextData}>
      <Header/>
      <main className="main">{children}</main>
      <Footer/>
    </UserContext.Provider>
  </>
}
