import './style.css'
import { useDebugState } from 'use-named-state'
import { useNavigate } from 'react-router-dom'
import { AddressRequest } from '../../../../models/Address'
import { DriverRouteUpsertRequest } from '../../../../models/route/DriverRoute'
import { driverRouteCreate } from '../../../../api/route/driver_route_api'
import { DRIVER__ROUTES__PATH } from '../../../../config/page_paths'
import AddressAutocomplete from '../../../../atoms/AddressAutocomplete/AddressAutocomplete'
import DateTimePicker_ from '../../../../atoms/DateTimePicker/DateTimePicker'
import Submit_ from '../../../../atoms/Submit/Submit'
import TextField_ from '../../../../atoms/TextField/TextField'
import Divider_ from '../../../../atoms/Divider/Divider'
import Text_ from "../../../../atoms/Text/Text"
import InfoIcon_ from "../../../../atoms/icons/InfoIcon"

export default function DriverRouteCreate() {
  const navigate = useNavigate()
  const [pickupFrom, setPickupFrom] = useDebugState<AddressRequest | undefined>('pickupFrom', undefined)
  const [pickupAt, setPickupAt] = useDebugState<any>('pickupAt', undefined)
  const [pickupAtValid, setPickupAtValid] = useDebugState<boolean>('pickupAtValid', true)
  const [deliveryTo, setDeliveryTo] = useDebugState<AddressRequest | undefined>('deliveryTo', undefined)
  const [deliveryAt, setDeliveryAt] = useDebugState<any>('setDeliveryAt', undefined)
  const [deliveryAtValid, setDeliveryAtValid] = useDebugState<boolean>('deliveryAtValid', true)
  const [bufferDistanceMiles, setBufferDistanceMiles] = useDebugState<number>('bufferDistanceMiles', 10)

  const [isUploading, setIsUploading] = useDebugState<boolean>('isUploading', false)

  function onPickupAtChange(newValue: any) {
    setPickupAt(newValue)
    setPickupAtValid(true)
  }

  function onDeliveryAtChange(newValue: any) {
    setDeliveryAt(newValue)
    setDeliveryAtValid(true)
  }

  function validateSubmitRequest() {
    let valid = true

    if (!pickupAt) {
      setPickupAtValid(false)
      valid = false
    }

    if (!deliveryAt) {
      setDeliveryAtValid(false)
      valid = false
    }

    return valid
  }

  function handleSubmit(event: any) {
    event.preventDefault()
    setIsUploading(true)
    if (validateSubmitRequest()) {
      const routeUpsertRequest = {
        buffer_distance_miles: bufferDistanceMiles, routes: [{
          address: pickupFrom, datetime: pickupAt,
        }, {
          address: deliveryTo, datetime: deliveryAt,
        },]
      } as DriverRouteUpsertRequest
      driverRouteCreate(routeUpsertRequest)
        .then(route => navigate(`${DRIVER__ROUTES__PATH}/${route.route_id}`))
        .catch(e => console.log(`DriverRouteCreate.handleSubmit.driverRouteCreate: ${e}`))
        .finally(() => setIsUploading(false))
    } else {
      console.log('DriverRouteCreate.handleSubmit: validateSubmitRequest()==false')
      setIsUploading(false)
    }
  }

  return <div className='driver_route_create__fields'>
    <Divider_ text='New Route' className='driver_route_create__divider__create_a_route'/>
    <div className='driver_route_create__description'>
      <InfoIcon_/>
      <Text_ content="Please provide your Route details and we will search Orders for you" className="driver_route_create__title"/>
    </div>
    <AddressAutocomplete placeholder='Start from' onChange={setPickupFrom} className='driver_route_create__pickup_from'/>
    <DateTimePicker_ label='Start time' value={pickupAt} onChange={onPickupAtChange} isValid={pickupAtValid} className='driver_route_create__pickup_at'/>
    <AddressAutocomplete placeholder='Finish to' onChange={setDeliveryTo} className='driver_route_create__delivery_to'/>
    <DateTimePicker_ label='Finish time' value={deliveryAt} onChange={onDeliveryAtChange} isValid={deliveryAtValid} className='driver_route_create__delivery_at'/>
    <TextField_ label='Search buffer distance, miles' value={bufferDistanceMiles} onChange={(value: string) => setBufferDistanceMiles(Number(value))}
                className='driver_route_create__buffer_distance_mi'/>

    <Submit_ onClick={handleSubmit} isUploading={isUploading} className='driver_route_create__submit'/>
  </div>
}
