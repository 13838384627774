import "./style.css"
import Button_, { ButtonStyle } from "../../../../atoms/Button/Button"
import { CSSProperties, ReactNode } from "react"
import { useDebugState } from "use-named-state"
import Title from "./Title"
import { OrderPackage2 } from "./OrderCreateV2"
import { OrderV2 } from "../../../../models/order/OrderV2"
import { useMobile } from "./utils"
import CloseIcon_ from "../../../../atoms/icons/CloseIcon"
import IconButton from "@mui/material/IconButton"

interface Range {
  min: number
  max: number
}

const LENGTH_IN_RANGE = { min: 1, max: 30 } as Range
const WIDTH_IN_RANGE = { min: 1, max: 30 } as Range
const HEIGHT_IN_RANGE = { min: 1, max: 30 } as Range


const HEADER_CELL_STYLE = {
  flex: 1, border: "0.5px solid rgba(0, 0, 0, 0.27)", backgroundColor: "rgba(37,150,243,0.07)", textAlign: "center", padding: "0.5em 0",
} as CSSProperties

const DATA_CELL_STYLE = {
  // flex: 1,
  border: "0.5px solid rgba(0, 0, 0, 0.27)", backgroundColor: "white", textAlign: "center", padding: "0.5em 0", height: "3em", fontSize: "1.0em", width: "33.3333%",
} as CSSProperties

const DATA_CELL__DISABLED__STYLE = {
  // flex: 1,
  border: "0.5px solid rgba(0, 0, 0, 0.27)", backgroundColor: "white", textAlign: "center", padding: "0.5em 0", height: "3em", fontSize: "1.0em", width: "33.3333%", color: "rgba(0, 0, 0, 0.38)"
} as CSSProperties


interface Props {
  disabled: boolean
  packages: OrderPackage2[]
  setPackages: (value: OrderPackage2[]) => void
  onPackageComplete: () => Promise<any>
  thenAction: (order: OrderV2) => void
}

export default function OrderCreateV2_2_Package({ disabled, packages, setPackages, onPackageComplete, thenAction }: Props) {

  const [showRateEnabled, setShowRateEnabled] = useDebugState<boolean>("showRateEnabled", false)
  const [lastRowValid, setLastValueValid] = useDebugState("lastValueValid", false)

  function checkLastRow(values: OrderPackage2[]): boolean {
    if (values.length === 0) {
      return true
    }
    const lastIndex = values.length - 1
    return values[lastIndex].length_in !== undefined && values[lastIndex].width_in !== undefined && values[lastIndex].height_in !== undefined
  }

  function onLengthChange(index: number, value: number) {
    const newPackages = [...packages]
    newPackages[index].length_in = value
    newPackages[index].length_in_valid = value >= LENGTH_IN_RANGE.min && value <= LENGTH_IN_RANGE.max
    setLastValueValid(checkLastRow(newPackages))
    setPackages(newPackages)
    setShowRateEnabled(isPackagesFilled(newPackages))
  }

  function onWidthChange(index: number, value: number) {
    const newPackages = [...packages]
    newPackages[index].width_in = value
    newPackages[index].width_in_valid = value >= WIDTH_IN_RANGE.min && value <= WIDTH_IN_RANGE.max
    setLastValueValid(checkLastRow(newPackages))
    setPackages(newPackages)
    setShowRateEnabled(isPackagesFilled(newPackages))
  }

  function onHeightChange(index: number, value: number) {
    const newPackages = [...packages]
    newPackages[index].height_in = value
    newPackages[index].height_in_valid = value >= HEIGHT_IN_RANGE.min && value <= HEIGHT_IN_RANGE.max
    setLastValueValid(checkLastRow(newPackages))
    setPackages(newPackages)
    setShowRateEnabled(isPackagesFilled(newPackages))
  }

  function removeOrClearRow(index: number) {
    const updatedPackages = packages.filter((p, i) => i !== index)
    if (index === packages.length - 1) {
      updatedPackages[index] = {} as OrderPackage2;
    }
    setLastValueValid(checkLastRow(updatedPackages))
    setPackages(updatedPackages)
    setShowRateEnabled(isPackagesFilled(updatedPackages))
  }

  function isPackagesFilled(values: OrderPackage2[]): boolean {
    if (values.length === 1 && isEmpty(values[0])) {
      return false
    }
    return values.every(value => isPackageValid(value))
  }

  function isEmpty(orderPackage: OrderPackage2): boolean {
    return orderPackage.length_in_valid === undefined && orderPackage.height_in_valid === undefined && orderPackage.width_in_valid === undefined
  }

  function isPackageValid(orderPackage: OrderPackage2): boolean {
    return isEmpty(orderPackage) || (orderPackage.length_in_valid === true && orderPackage.height_in_valid === true && orderPackage.width_in_valid === true)
  }

  function buildErrorCell(range: Range, valid?: boolean): ReactNode {
    return <div style={{ fontSize: "10px", color: "red", textAlign: "center", flex: "1" }}>
      {valid === false && `Should be in range from ${range.min} to ${range.max}`}
    </div>
  }

  const isMobile = useMobile()

  function createRow(i: number): ReactNode {
    return <div key={i} style={{ position: "relative", width: isMobile ? "94%" : "100%" }}>
      <div key={i} style={{ display: "flex" }}>
        <input
          style={disabled ? DATA_CELL__DISABLED__STYLE : DATA_CELL_STYLE}
          disabled={disabled}
          type="tel"
          value={packages[i].length_in || ""}
          onChange={e => onLengthChange(i, Number(e.target.value))}
          className={`user_order_create_v2__package__table__${i}__length_in`}
        />
        <input
          style={disabled ? DATA_CELL__DISABLED__STYLE : DATA_CELL_STYLE}
          disabled={disabled}
          type="tel"
          onChange={e => onWidthChange(i, Number(e.target.value))}
          value={packages[i].width_in || ""}
          className={`user_order_create_v2__package__table__${i}__width_in`}
        />
        <input
          style={disabled ? DATA_CELL__DISABLED__STYLE : DATA_CELL_STYLE}
          disabled={disabled}
          type="tel"
          value={packages[i].height_in || ""}
          onChange={e => onHeightChange(i, Number(e.target.value))}
          className={`user_order_create_v2__package__table__${i}__height_in`}
        />
      </div>
      {!isPackageValid(packages[i]) && <>
          <div key={i} style={{ display: "flex" }}>
            {buildErrorCell(LENGTH_IN_RANGE, packages[i].length_in_valid)}
            {buildErrorCell(WIDTH_IN_RANGE, packages[i].width_in_valid)}
            {buildErrorCell(HEIGHT_IN_RANGE, packages[i].height_in_valid)}
          </div>
      </>}
      {!disabled && <div style={{ position: "absolute", top: "0em", right: "-2.5em" }}>
          <IconButton size="medium" color="primary" onClick={() => removeOrClearRow(i)} className={`user_order_create_v2__package__table__${i}__delete_button`}>
              <CloseIcon_/>
          </IconButton>
      </div>}
    </div>
  }

  return <>
    <div style={{ display: "flex", flexDirection: "column", margin: "auto", paddingTop: "0.25em", paddingBottom: "1.5em", width: useMobile() ? "100%" : "600px" }}>
      <Title text="Tell us more about your package" className="user_order_create_v2__package__title"/>
      <div style={{ width: useMobile() ? "94%" : "100%" }} className="user_order_create_v2__package__table">
        <div style={{ display: "flex", flexDirection: "row" }} className="user_order_create_v2__package__table__header">
          <div style={HEADER_CELL_STYLE}>
            <div style={{ fontWeight: "bold" }}>Length</div>
            <div style={{ fontSize: "0.75em" }}>{`Max. ${LENGTH_IN_RANGE.max} in`}</div>
          </div>
          <div style={HEADER_CELL_STYLE}>
            <div style={{ fontWeight: "bold" }}>Width</div>
            <div style={{ fontSize: "0.75em" }}>{`Max. ${WIDTH_IN_RANGE.max} in`}</div>
          </div>
          <div style={HEADER_CELL_STYLE}>
            <div style={{ fontWeight: "bold" }}>Height</div>
            <div style={{ fontSize: "0.75em" }}>{`Max. ${HEIGHT_IN_RANGE.max} in`}</div>
          </div>
        </div>
      </div>
      {packages.map((_, i) => createRow(i))}
      <div style={{ flex: 1 }}>
        <Button_
          label="+ Add another package"
          size="medium"
          disabled={disabled || !lastRowValid}
          buttonStyle={ButtonStyle.TEXT}
          onClickVoid={() => {
            const newPackages = [...packages, {}]
            setLastValueValid(checkLastRow(newPackages))
            setPackages(newPackages)
            setShowRateEnabled(isPackagesFilled(newPackages))
          }}
          className="user_order_create_v2__package__table__add_another_package"
        />
      </div>
      <div style={{ margin: "auto", marginTop: "1em" }}>
        <Button_
          label="Show my rate"
          size="large"
          onClick={onPackageComplete}
          disabled={disabled || !showRateEnabled}
          thenAction={thenAction}
          className="user_order_create_v2__package__show_my_rate"
        />
      </div>
    </div>
  </>
}
