import './style.css'
import Divider_ from '../../../../atoms/Divider/Divider'
import TextField_ from '../../../../atoms/TextField/TextField'
import { userTimeZoneDate } from '../../../../utils/time_utils'
import { BaseRoute } from '../../../../models/route/BaseRoute'

interface Props {
  route: BaseRoute
}

export default function RouteCardBasicInfo({ route }: Props) {
  return <>
    <Divider_ text='Basic info' className='route_card__divider__basic_info'/>
    <TextField_ label='Route ID' value={route.route_id} className='route_card__route_id'/>
    <TextField_ label='User ID' value={route.user_id} className='route_card__user_id'/>
    <TextField_ label='Created at' value={userTimeZoneDate(route.created_at)} className='route_card__created_at'/>
    <TextField_ label='Updated at' value={userTimeZoneDate(route.updated_at)} className='route_card__updated_at'/>
    <TextField_ label='Buffer, mi' value={route.buffer_distance_miles} className='route_card__buffer_distance_miles'/>
  </>
}
