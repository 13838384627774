import './style.css'
import DriverDashboard from '../../../../components/dashboard/DriverDashboard/DriverDashboard'
import DriverTab, { DriverTabConfig } from '../../../../components/tab/DriverTab/DriverTab'

export default function DriverDashboardPage() {
  return <div className="driver_dashboard_page">
    <DriverTab tabIndex={DriverTabConfig.Dashboard.index}/>
    <DriverDashboard/>
  </div>
}
